import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { notification } from "antd";
import { isError } from "utils/api/models/api-response";
import { UpdateReportAsync } from "services/reports";
import i18next from "i18next";

export default function UpdateReport() {
  const queryClient = useQueryClient();
  return {
    ...useMutation(UpdateReportAsync, {
      onSuccess: async (res) => {
        if (!isError(res)) {
          notification.open({
            message:
              i18next.language === "ar"
                ? "تم تحديث التقرير"
                : "Report updated successfully",
          });
          queryClient.invalidateQueries(["Report", res.data.id]);
          queryClient.invalidateQueries("Reports");
        }
      },
    }),
  };
}
