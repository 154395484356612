import { Form, Select } from "antd";
import { useEffect, useState } from "react";
import useUsersCompany from "hooks/query/companies/useUsersCompany";
import { UserModel } from "models/users";
import { settingInterface } from "DTO/interfaces";
import { loadingMoreSpinner } from "utils/ui/ScrollLoading";
import { t } from "i18next";

const { Option } = Select;

export const AddPlanUsers = ({
  name,
  required = false,
  disabled = false,
  enabled = false,
  multiple,
  users,
  search,
  setSearch,
  isLoading,
}: {
  name: string;
  required?: boolean;
  disabled?: boolean;
  enabled: boolean;
  multiple?: boolean;
  users: UserModel[];
  search: string;
  setSearch: any;
  isLoading: boolean;
}) => {
  const changeClientSearch = (e: any) => {
    setSearch(e);
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <Form.Item
          label={<p style={{ margin: 0 }}>{t("client_1")}</p>}
          name={name}
          dependencies={[name[0]]}
          rules={[
            {
              required: required,
              message: t("please_select_requester"),
            },
          ]}
        >
          <Select
            showSearch
            disabled={disabled}
            mode={multiple ? "multiple" : undefined}
            optionFilterProp="children"
            // filterOption={filterOption as any}
            onSearch={(e: any) => changeClientSearch(e)}
            dropdownRender={(menu: any) => <div>{menu}</div>}
            // onFocus={() => setUsersFoucesed(true)}
            notFoundContent={t("no_data")}
          >
            {isLoading
              ? loadingMoreSpinner
              : users?.map((item) => (
                  <Option
                    style={{ direction: "rtl", textAlign: "right" }}
                    key={item?.id?.toString()}
                  >
                    {item?.full_name}
                  </Option>
                ))}
          </Select>
        </Form.Item>
      </div>
    </>
  );
};
