import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { isError } from "utils/api/models/api-response";
import { EditCompanyPlaceAsync } from "services/places";

export default function EditPlace() {
  const queryClient = useQueryClient();
  return {
    ...useMutation(EditCompanyPlaceAsync, {
      onSuccess: async (res) => {
        if (!isError(res)) {
          queryClient.invalidateQueries("Places");
          queryClient.invalidateQueries("Place");
        }
      },
    }),
  };
}
