import { CaretDownOutlined, SyncOutlined } from "@ant-design/icons";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Dropdown,
  Input,
  Radio,
  Select,
  Switch,
} from "antd";
import type { MenuProps } from "antd";
import { useEffect, useState } from "react";
import { useWidth } from "utils/helpers/use-is-mobile";
import { Roles, Ticket, TicketKeyName } from "utils/helpers/static_data";
import { hasPermission } from "components/permissionsGate";
import { StatusColor } from "utils/helpers/ticket_status";
import Color from "utils/helpers/export-sass-colors";
import dayjs from "dayjs";
import isTomorrow from "dayjs/plugin/isTomorrow";
import { ItemType } from "antd/es/menu/hooks/useItems";
import format from "utils/helpers/date-format";
import { UserModel } from "models/users";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import i18next, { t } from "i18next";
import { direction } from "utils/ui/LocaleDirection";
import { AiOutlineSchedule } from "react-icons/ai";

dayjs.extend(isTomorrow);
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;

export interface filters {
  Status?: string;
  Priority?: "-1" | "0" | "1" | "2";
  Sort?: "" | "-id" | "+id";
  isActive?: 0 | 1;
  myTickets?: 0 | number;
  myClientRequests?: 0 | number;
  PdfReport?: "" | "yes" | "no";
  DateRange?: string;
  SearchText?: string;
  SearchBy?: string;
  setStatus?(status: filters["Status"]): void;
  setPdfReport?(pdfReport: filters["PdfReport"]): void;
  setPriority?(priority: filters["Priority"]): void;
  setSort?(priority: filters["Sort"]): void;
  setDate?(date: any): void;
  setSearch?(search: string): void;
  setSearchBy?(searchBy: string): void;
  multiSearch?: "ticket" | "user" | "company" | "finalReport";
  setIsActive?(IsActive: filters["isActive"]): void;
  setMyClientRequests?(myClientRequests: filters["myClientRequests"]): void;
  refetch?(): void;
  isFetching?: boolean;
  addButton?: any;
  setScheduleModalIsOpen?: Function;
}

const ClientRequestsFillters = ({
  Status,
  Priority,
  Sort,
  isFetching,
  setStatus,
  setPriority,
  setSort,
  refetch,
  setIsActive,
  setMyClientRequests: setMyTickets,
  setSearch,
  setSearchBy,
  multiSearch,
  setDate,
  isActive,
  myTickets: myClientRequests,
  PdfReport,
  setPdfReport,
  addButton,
  DateRange,
  SearchText,
  SearchBy,
  setScheduleModalIsOpen,
}: filters) => {
  const { isMobile } = useWidth();

  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserData.Decrypt]);

  const ChangeDate = (e: any) => {
    if (e === null) {
      setDate?.("");
    } else if (e[0] !== null && e[1] !== null && e !== null) {
      setDate?.(e[0].format("YYYY-MM-DD") + "," + e[1].format("YYYY-MM-DD"));
    } else {
      setDate?.("");
    }
  };

  const filterByKyes = {
    ticket: {
      ticket_id: t("ticket_id"),
      creator_by: t("request_creator_by"),
      company_name: t("center"),
      requester_by: t("client_1"),
    },
    user: {
      name_search: t("name"),
      phone_number: t("contact_number"),
    },
    company: {
      short_code: t("company_code"),
      name: t("name"),
    },
    finalReport: {
      formal_report: t("final_report"),
      description: t("description"),
    },
  };
  const handleClick = (e: any) => {
    setFilterBy(KeyToValue(e.key));
    setSearchBy?.(e.key);
  };
  const KeyToValue = (key?: any): any => {
    let value: ItemType = {
      label: "...",
      key: "null",
      onClick: (e) => handleClick(e),
    };
    if (multiSearch !== undefined && key !== undefined)
      return {
        label: (filterByKyes as any)[multiSearch][key],
        key: Object.keys((filterByKyes as any)[multiSearch]).filter(
          (i) => i === key
        )[0],
        onClick: (e) => handleClick(e),
      } as ItemType;
    return value;
  };

  const [filterBy, setFilterBy] = useState<any>(KeyToValue());
  const [filterSearchText, setFilterSearchText] = useState<string>("");

  useEffect(() => {
    setFilterBy(KeyToValue(SearchBy));
    setFilterSearchText(SearchText!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchBy, SearchText, i18next.language]);

  const client_request: MenuProps["items"] = [
    KeyToValue("ticket_id"),
    KeyToValue("creator_by"),
    KeyToValue("company_name"),
    KeyToValue("requester_by"),
  ];
  const user: MenuProps["items"] = [
    KeyToValue("name_search"),
    KeyToValue("phone_number"),
  ];
  const company: MenuProps["items"] = [
    KeyToValue("short_code"),
    KeyToValue("name"),
  ];
  const final_report: MenuProps["items"] = [
    KeyToValue("description"),
    KeyToValue("formal_report"),
  ];
  return (
    <div
      dir={direction[i18next.language]}
      style={{ paddingTop: "10px" }}
      className="ticketBtns"
    >
      <div
        className="ticketsFilters"
        style={{
          display: "flex",
          gap: "1rem",
          justifyContent: "flex-start",
          height: "100%",
          flexWrap: isMobile ? "wrap" : "nowrap",
          marginTop: isMobile ? 10 : 0,
          width: isMobile ? "100%" : "initial",
        }}
      >
        {Status && (
          <div
            style={{
              flex: isMobile ? "50%" : "initial",
            }}
          >
            <div className="label">{t("ticket_status")}</div>
            <Select
              onChange={(e: filters["Status"]) => setStatus?.(e)}
              className={"changeStatusSelect status" + Status}
              style={isMobile ? {} : { width: "150px" }}
              value={Status}
              suffixIcon={
                <CaretDownOutlined
                  className="ant-select-suffix"
                  style={{ color: "white" }}
                />
              }
            >
              {hasPermission(["browse_tickets"]) && (
                <Option value={Ticket.create}>
                  <div className="dot" />
                  {t("all")}
                </Option>
              )}
              {hasPermission(["browse_ticket_pending"]) && (
                <Option value={Ticket.pending}>
                  <div
                    className="dot"
                    style={{ backgroundColor: StatusColor(1) }}
                  />
                  {TicketKeyName.pending()}
                </Option>
              )}
              {/* {hasPermission(["browse_ticket_priced"]) && (
                <Option value={Ticket.priced}>
                  <div
                    className="dot"
                    style={{ backgroundColor: StatusColor(9) }}
                  />
                  {TicketKeyName.priced()}
                </Option>
              )} */}
              {hasPermission(["browse_ticket_active"]) && (
                <Option value={Ticket.active}>
                  <div
                    className="dot"
                    style={{ backgroundColor: StatusColor(2) }}
                  />
                  {TicketKeyName.active()}
                </Option>
              )}
              {hasPermission(["browse_ticket_done"]) && (
                <Option value={Ticket.done}>
                  <div
                    className="dot"
                    style={{ backgroundColor: StatusColor(3) }}
                  />
                  {TicketKeyName.done()}
                </Option>
              )}
              {hasPermission(["browse_ticket_approved"]) && (
                <Option value={Ticket.approved}>
                  <div
                    className="dot"
                    style={{ backgroundColor: StatusColor(4) }}
                  />
                  {TicketKeyName.approved()}
                </Option>
              )}
              {hasPermission(["browse_ticket_denied"]) && (
                <Option value={Ticket.denied}>
                  <div
                    className="dot"
                    style={{ backgroundColor: StatusColor(5) }}
                  />
                  {TicketKeyName.denied()}
                </Option>
              )}
              {/* {hasPermission(["browse_ticket_paid"]) && (
                <>
                  <Option value={Ticket.partialPaid}>
                    <div
                      className="dot"
                      style={{ backgroundColor: StatusColor(11) }}
                    />
                    {TicketKeyName.partialPaid()}
                  </Option>
                  <Option value={Ticket.paid}>
                    <div
                      className="dot"
                      style={{ backgroundColor: StatusColor(10) }}
                    />
                    {User?.role_id === Roles.client
                      ? TicketKeyName.paidClient
                      : TicketKeyName.paid()}
                  </Option>
                </>
              )} */}
            </Select>
          </div>
        )}

        {Sort !== undefined && (
          <div
            style={{
              flex: isMobile ? "50%" : "initial",
              paddingLeft: isMobile ? 10 : 0,
            }}
          >
            <div className="label">{t("requests_sorting")}</div>
            <Select
              onChange={(e: filters["Sort"]) => setSort?.(e)}
              className={"changeStatusSelect"}
              style={isMobile ? {} : { width: "170px" }}
              value={Sort}
              suffixIcon={
                <CaretDownOutlined
                  className="ant-select-suffix"
                  style={{ color: "white" }}
                />
              }
            >
              <Option key={""}>{t("last_updates")}</Option>
              <Option key={"-id"}>{t("from_latest_to_oldes")}</Option>
              <Option key={"+id"}>{t("from_oldest_to_latest")}</Option>
            </Select>
          </div>
        )}

        {setSearch !== undefined && multiSearch === undefined && (
          <Search
            style={{
              width: "unset",
              flex: isMobile ? "50%" : "initial",
              marginTop: "21px",
              marginLeft: isMobile ? "0" : "10px",
              paddingLeft: "6px",
            }}
            placeholder={t("search")}
            onSearch={(e: any) => setSearch?.(e)}
            onChange={(e: any) => {
              if (e.target.value === "") setSearch?.("");
              else if (e.target.value.length > 2) setSearch?.(e.target.value);
              else if (e.target.value.length < 3) setSearch?.("");
            }}
          />
        )}

        {setSearch !== undefined && multiSearch !== undefined && (
          <div
            className="multiSearch"
            style={{
              marginLeft: isMobile ? "0" : "10px",
              marginTop: "4px",
              flex: isMobile ? "50%" : "initial",
            }}
          >
            <Dropdown
              menu={{
                items:
                  multiSearch === "ticket"
                    ? client_request
                    : multiSearch === "user"
                    ? user
                    : multiSearch === "company"
                    ? company
                    : multiSearch === "finalReport"
                    ? final_report
                    : [],
              }}
              trigger={["click"]}
            >
              <div
                style={{
                  fontSize: "12px",
                  marginRight: "10px",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                }}
              >
                <CaretDownOutlined /> {t("search_by")}
                <span style={{ color: Color.blue_2 }}>{filterBy?.label}</span>
              </div>
            </Dropdown>
            <Search
              style={{
                marginTop: "4px",
                paddingLeft: "6px",
              }}
              value={filterSearchText}
              placeholder={t("search")}
              onSearch={(e: any) => setSearch?.(e)}
              onChange={(e: any) => {
                setFilterSearchText(e.target.value);
                if (e.target.value === "") setSearch?.("");
                else if (e.target.value.length > 2) setSearch?.(e.target.value);
              }}
            />
          </div>
        )}

        {setDate !== undefined && !isMobile && (
          <ConfigProvider direction="ltr">
            <RangePicker
              style={{ marginLeft: "10px" }}
              disabledDate={(current) => {
                return (
                  current.isBefore(dayjs("2022-01-01")) ||
                  current.isAfter(dayjs().subtract(-1, "day"))
                );
              }}
              value={
                DateRange !== ""
                  ? [
                      dayjs(DateRange?.split(",")[0]),
                      dayjs(DateRange?.split(",")[1]),
                    ]
                  : undefined
              }
              onChange={(e: any) => ChangeDate(e)}
              showTime
              format={format.date}
            />
          </ConfigProvider>
        )}
        {PdfReport !== undefined &&
          !isMobile &&
          User?.role_id !== Roles.client && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "10px",
                marginTop: "4px",
                flex: "initial",
              }}
            >
              <div className="label">تقرير PDF:</div>
              <Radio.Group
                name="pdf-report"
                optionType="button"
                buttonStyle="solid"
                value={PdfReport}
                style={{ whiteSpace: "nowrap", height: "40px" }}
              >
                <Radio
                  value={"no"}
                  onClick={(e) => {
                    if (PdfReport === "yes" || PdfReport === "")
                      setPdfReport?.("no");
                    else setPdfReport?.("");
                  }}
                  style={{ height: "inherit", padding: "4px 15px" }}
                >
                  يحتوي
                </Radio>
                <Radio
                  value={"yes"}
                  onClick={(e) => {
                    if (PdfReport === "no" || PdfReport === "")
                      setPdfReport?.("yes");
                    else setPdfReport?.("");
                  }}
                  style={{ height: "inherit", padding: "4px 15px" }}
                >
                  بدون
                </Radio>
              </Radio.Group>
            </div>
          )}
        {isActive !== undefined &&
          !isMobile &&
          User?.role_id !== Roles.client &&
          User?.role_id !== Roles.accountant && (
            <div style={{ flex: isMobile ? "50%" : "initial" }}>
              <div className="label" style={{ whiteSpace: "nowrap" }}>
                {t("active")}
              </div>
              <Switch
                defaultChecked={false}
                checked={isActive === 1}
                onChange={(e: any) => (e ? setIsActive?.(1) : setIsActive?.(0))}
                onClick={(e: any) => (e ? setIsActive?.(1) : setIsActive?.(0))}
              />
            </div>
          )}
      </div>

      <div
        style={{
          display: "flex",
          alignItems: isMobile ? "center" : "flex-end",
          width: isMobile ? "100%" : "initial",
        }}
      >
        {isActive !== undefined &&
          isMobile &&
          User?.role_id !== Roles.client &&
          User?.role_id !== Roles.accountant && (
            <div style={{ flex: isMobile ? "50%" : "initial" }}>
              <span className="label">{t("under_work")}</span>
              <Switch
                defaultChecked={false}
                checked={isActive === 1}
                onChange={(e: any) => (e ? setIsActive?.(1) : setIsActive?.(0))}
                onClick={(e: any) => (e ? setIsActive?.(1) : setIsActive?.(0))}
              />
            </div>
          )}
      </div>
      {User?.role_id == Roles.technical &&
        setScheduleModalIsOpen &&
        hasPermission(["browse_schedules"]) && (
          <Button type="primary" onClick={() => setScheduleModalIsOpen(true)}>
            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <AiOutlineSchedule />
              <p style={{ margin: 0 }}>{t("appointments")}</p>
            </div>
          </Button>
        )}
    </div>
  );
};

export default ClientRequestsFillters;
