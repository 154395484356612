import { Col, Form, Modal, Row, Input, Radio } from "antd";
import { useEffect, useState } from "react";
import { Ticket as TicketStatus } from "utils/helpers/static_data";
import { filters } from "components/filters/ticketsFillters";
import { UserModel } from "models/users";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import { isClient } from "utils/helpers/check-roles";
import RequestedByInput from "components/ticket/addTicketModalInputs/RequestedByInput";
import "./style.css";
import AddTicket from "hooks/query/tickets/addTicket";
import { CloseOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { submitFormPlan } from "DTO/plan/create";
import useAddPlan from "hooks/query/plan/useAddPlan";
import { AddPlanUsers } from "./addPlanUsers";
import useUsersCompany, {
  useDoctors,
} from "hooks/query/companies/useUsersCompany";

interface AddPlanModalProps {
  isOpen: boolean;
  setIsOpen: any;
}

const AddPlanModal = (props: AddPlanModalProps) => {
  const [form] = Form.useForm<submitFormPlan>();
  const createPlan = useAddPlan();

  const AddNewClientRequest = (e: submitFormPlan) => {
    console.log(e);
    const formData = new FormData();

    e.doctors_saturday.forEach((element: any, index: number) => {
      formData.append(`doctors[saturday][${index}]`, element);
    });

    e.doctors_sunday.forEach((element: any, index: number) => {
      formData.append(`doctors[sunday][${index}]`, element);
    });

    e.doctors_monday.forEach((element: any, index: number) => {
      formData.append(`doctors[monday][${index}]`, element);
    });

    e.doctors_tuesday.forEach((element: any, index: number) => {
      formData.append(`doctors[tuesday][${index}]`, element);
    });

    e.doctors_wednesday.forEach((element: any, index: number) => {
      formData.append(`doctors[wednesday][${index}]`, element);
    });

    createPlan.mutateAsync(formData).then(() => {
      props.setIsOpen(false);
      form.resetFields();
    });
  };

  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
  }, [UserData.Decrypt]);

  const handleClose = () => {
    props.setIsOpen(false);
    form.resetFields();
  };

  const [search1, setSearch1] = useState<string>("");
  const [search2, setSearch2] = useState<string>("");
  const [search3, setSearch3] = useState<string>("");
  const [search4, setSearch4] = useState<string>("");
  const [search5, setSearch5] = useState<string>("");
  const [selectedUsersIds, setSelectedUsersIds] = useState<any[]>([]);
  const [userCompanyData, setUserCompanyData] = useState<any[]>([]);
  const UsersCompany = useDoctors(0, undefined, undefined, true, "");

  // Update userCompanyData state when UsersCompany changes
  useEffect(() => {
    setUserCompanyData(UsersCompany?.data?.data || []);
  }, [UsersCompany?.data?.data]);

  // Filter userCompanyData based on selectedUserIds and search term
  useEffect(() => {
    setUserCompanyData(
      UsersCompany?.data?.data.filter(
        (user) => !selectedUsersIds.includes(user.id.toString())
      ) || []
    );
  }, [selectedUsersIds]);
  const handleFormChange = (changedValues: any, allValues: any) => {
    // Extract selected user IDs from form values
    const userIds: number[] = Object.values(allValues)
      .flat()
      .filter((id): id is number => typeof id != "undefined");
    setSelectedUsersIds(userIds);
  };
  return (
    <Modal
      open={props.isOpen}
      width={800}
      onCancel={handleClose}
      onOk={form.submit}
      maskClosable={false}
      confirmLoading={createPlan.isLoading}
      bodyStyle={{
        padding: "3rem",
        backgroundColor: "#f4f4f4",
        maxHeight: "80vh",
        overflowY: "scroll",
      }}
      closable={false}
      centered
    >
      <>
        <div className="modal-header">
          <h3 className="title">{t("add_plan")}</h3>
          <span className="close-icon" onClick={handleClose}>
            <CloseOutlined />
          </span>
        </div>
        <Form
          style={{ marginTop: "1rem" }}
          key={""}
          layout="vertical"
          onFinish={AddNewClientRequest}
          form={form}
          requiredMark={false}
          scrollToFirstError={{ block: "center", behavior: "smooth" }}
          onValuesChange={handleFormChange}
        >
          <Row style={{ alignItems: "center" }}>
            <Col span={4}>
              <p>{t("saturday")}</p>
            </Col>
            <Col span={20}>
              <AddPlanUsers
                name={"doctors_saturday"}
                multiple
                enabled
                required
                users={userCompanyData}
                search={search1}
                setSearch={setSearch1}
                isLoading={UsersCompany.isLoading}
              />
            </Col>
            <Col span={4}>
              <p>{t("sunday")}</p>
            </Col>
            <Col span={20}>
              <AddPlanUsers
                name={"doctors_sunday"}
                multiple
                enabled
                required
                users={userCompanyData}
                search={search2}
                setSearch={setSearch2}
                isLoading={UsersCompany.isLoading}
              />
            </Col>
            <Col span={4}>
              <p>{t("monday")}</p>
            </Col>
            <Col span={20}>
              <AddPlanUsers
                name={"doctors_monday"}
                multiple
                enabled
                required
                users={userCompanyData}
                search={search3}
                setSearch={setSearch3}
                isLoading={UsersCompany.isLoading}
              />
            </Col>
            <Col span={4}>
              <p>{t("tuesday")}</p>
            </Col>
            <Col span={20}>
              <AddPlanUsers
                name={"doctors_tuesday"}
                multiple
                enabled
                required
                users={userCompanyData}
                search={search4}
                setSearch={setSearch4}
                isLoading={UsersCompany.isLoading}
              />
            </Col>
            <Col span={4}>
              <p>{t("wednesday")}</p>
            </Col>
            <Col span={20}>
              <AddPlanUsers
                name={"doctors_wednesday"}
                multiple
                enabled
                required
                users={userCompanyData}
                search={search5}
                setSearch={setSearch5}
                isLoading={UsersCompany.isLoading}
              />
            </Col>
          </Row>
        </Form>
      </>
    </Modal>
  );
};

export default AddPlanModal;
