import { hasPermission } from "components/permissionsGate/index";
import { AxiosRequestConfig } from "axios";
import ApiService from "utils/api/api-service";
import ApiResponse, { isError } from "utils/api/models/api-response";
import { ApiErrorNotification } from "utils/ui/notificationService";

class UsersService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: process.env.REACT_APP_API_URL,
      ...config,
    });
  }

  public async FetchTechnicalsUsers(
    page: any,
    search: any
  ): Promise<ApiResponse<any>> {
    return this.get(
      `/api/getUserByRole?role=representative&page=${page}&items_per_page=8&filter[name_search]=${search}&index_type=list`
    );
  }
  public async FetchTechnicals(
    page: any,
    search: any
  ): Promise<ApiResponse<any>> {
    return this.get(
      `/api/getTechnicals?page=${page}&items_per_page=8&filter[name_search]=${search}`
    );
  }
  public async FetchSingleTechnical(id: any): Promise<ApiResponse<any>> {
    return this.get(`/api/users/${id}?show_type=single`);
  }
  public async FetchTop(): Promise<ApiResponse<any>> {
    return this.get(`/api/users/statistics/top-technicals`);
  }
  public async FetchCurrent(): Promise<ApiResponse<any>> {
    return this.get(`/api/current-user`);
  }
  public async UpdateUser(data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/current-user`, data.form);
  }
  public async Login(data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/login`, data);
  }
  public async SendOtp(data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/send-otp-again`, data);
  }
  public async VerifyOtp(data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/verify-phone-number`, data);
  }
  public async UploadImg(data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/update-profile-avatar`, data);
  }
  public async UploadImgById(id: any, data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/users/${id}/avatar`, data);
  }
  public async StoreImg(data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/users/avatar`, data);
  }
  public async ResetPassword(data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/reset-password`, data);
  }

  public async Fetch(
    page: any,
    perPage: any,
    searchBy: any,
    search: any,
    role?: any
  ): Promise<ApiResponse<any>> {
    return this.get(
      `/api/users?page=${page}&items_per_page=${perPage}${
        search !== "" ? "&filter[" + searchBy + "]=" + search : ""
      }${role ? "&filter[role]=" + role : ""}`
    );
  }
  public async FetchSingle(id: any): Promise<ApiResponse<any>> {
    return this.get(`/api/users/${id}?show_type=single`);
  }
  public async add(data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/users`, data);
  }
  public async edit(id: any, data: any): Promise<ApiResponse<any>> {
    return this.put(`/api/users/${id}`, data);
  }
  public async delete(id: any): Promise<ApiResponse<any>> {
    return super.delete(`/api/users/${id}`);
  }
  public async deleteCurrent({ id, token }: any): Promise<ApiResponse<any>> {
    return super.delete(`/api/users/${id}`, token);
  }
  public async FetchRoles(): Promise<ApiResponse<any>> {
    return this.get(`/api/users/roles?items_per_page=200`);
  }
  public async CheckAccount(): Promise<ApiResponse<any>> {
    return this.get(`/api/account-info`);
  }
  public async activate(id: any, data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/users/${id}/account-activation`, data);
  }
  public async verified(id: number): Promise<ApiResponse<any>> {
    return this.post(`/api/verified-users/${id}`);
  }
}

const usersService = new UsersService();

export const FetchTechnicalsUsers = async (page: any, search: any) => {
  const result: any = await usersService.FetchTechnicalsUsers(page, search);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const FetchTechnicals = async (page: any, search: any) => {
  const result: any = await usersService.FetchTechnicals(page, search);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const SendOtpAsync = async (data: any) => {
  const result: any = await usersService.SendOtp(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const CheckAccountAsync = async () => {
  const result: any = await usersService.CheckAccount();
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result.data;
  }
};
export const VerifyOtpAsync = async (data: any) => {
  const result: any = await usersService.VerifyOtp(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const FetchTechnicalUser = async (id: any) => {
  if (hasPermission(["read_technical_profiles"])) {
    const result: any = await usersService.FetchSingleTechnical(id);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const FetchTopTechnicals = async () => {
  if (hasPermission(["browse_technical_profiles"])) {
    const result: any = await usersService.FetchTop();
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const FetchCurrentUser = async () => {
  const result: any = await usersService.FetchCurrent();
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const EditCurrentUser = async (data: any) => {
  const result: any = await usersService.UpdateUser(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const resetPassword = async (data: any) => {
  const result: any = await usersService.ResetPassword(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const UploadImgAsync = async (data: any) => {
  const result: any = await usersService.UploadImg(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const UploadImgByIdAsync = async ({ id, data }: any) => {
  const result: any = await usersService.UploadImgById(id, data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const StoreImgAsync = async (data: any) => {
  const result: any = await usersService.StoreImg(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const FetchUsers = async (
  page: any,
  perPage: any,
  searchBy: any,
  search: any,
  role?: any
) => {
  const result: any = await usersService.Fetch(
    page,
    perPage,
    searchBy,
    search,
    role
  );
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const FetchUser = async (id: any) => {
  if (parseInt(id) !== 0) {
    const result: any = await usersService.FetchSingle(id);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};

export const AddUserAsync = async (data: any) => {
  const result: any = await usersService.add(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const EditUserAsync = async ({ id, data }: any) => {
  const result: any = await usersService.edit(id, data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const DeleteUserAsync = async (id: any) => {
  const result: any = await usersService.delete(id);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const DeleteCurrentUserAsync = async (data: any) => {
  const result: any = await usersService.deleteCurrent(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const FetchRolesAsync = async () => {
  const result: any = await usersService.FetchRoles();
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const ActivateUserAsync = async ({ id, data }: any) => {
  const result: any = await usersService.activate(id, data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const VerifiedUserAsync = async (id: number) => {
  const result: any = await usersService.verified(id);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
