import { useQuery } from "react-query";
import { FetchReport } from "services/reports";
import ApiResult from "utils/api/models/api-result";
import { ReportModel } from "models/reports";
import { ApiError } from "utils/api/models/api-error";

export default function useReport(id: number) {
  const queryKey = ["Report", id];
  return {
    ...useQuery<ApiResult<ReportModel>, ApiError>(
      queryKey,
      () => FetchReport(id),
      {
        cacheTime: 0,
        staleTime: Infinity,
        refetchOnWindowFocus: false,
      }
    ),
  };
}
