import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Spin } from "antd";
import { useEffect } from "react";
import useOldReports from "hooks/query/reports/useOldReports";
import dayjs from "dayjs";
import format from "utils/helpers/date-format";
import { t } from "i18next";

/**
 * show old reports details
 */
const OldReportModal = (props: any) => {
  const ReportsData = useOldReports(props.id, props.modal);
  const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
  const CloseReportsModal = () => {
    props.close();
  };

  useEffect(() => {
    if (props.modal && !ReportsData.isLoading) {
      ReportsData.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, props.modal]);
  return (
    <Modal
      key={"oldReportsModal"}
      width={700}
      onCancel={() => CloseReportsModal()}
      title={t("old_tichnical_report")}
      bodyStyle={{ padding: "0 24px" }}
      open={props.modal}
      footer={[
        <div style={{ display: "flex" }}>
          <Button
            type="primary"
            onClick={() => CloseReportsModal()}
            className="closeBtn"
            key="back"
          >
            اغلاق
          </Button>
        </div>,
      ]}
      centered
    >
      {ReportsData?.data?.data && !ReportsData.isLoading ? (
        <>
          {ReportsData?.data?.data.length === 0 ? (
            <div style={{ textAlign: "center", padding: "16px 24px" }}>
              {t("no_data")}
            </div>
          ) : (
            <div
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: "70vh",
                padding: "10px",
              }}
            >
              {ReportsData?.data?.data.map((Report: any, index: any) => (
                <div
                  style={{
                    padding: "30px 20px 25px",
                    marginBottom: 20,
                    position: "relative",
                    height: "auto",
                    overflow: "hidden",
                  }}
                  className="greyBox"
                  key={index}
                >
                  <div className="reportId">#{Report?.id}</div>
                  <Row>
                    <Col span={24} lg={24}>
                      <div
                        style={{ cursor: "initial" }}
                        className="reportUserInfo"
                      >
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                          className="username"
                        >
                          <img src={Report?.checkin_user?.avatar} alt="" />
                          <div>
                            <div>
                              {`${
                                Report?.checkin_user?.full_name !== undefined &&
                                Report?.checkin_user?.full_name !== null &&
                                Report?.checkin_user?.full_name
                              }`}
                            </div>
                            <span className="data">
                              {Report?.checkin_user?.job_title?.name}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col span={24} lg={10}>
                      <div className="labelReport">
                        {t("visit_checkin")}
                        <span dir="ltr" className="data">
                          {dayjs(Report?.time_in).format(format.date_time)}
                        </span>
                      </div>
                    </Col>
                    <Col span={24} lg={10}>
                      {Report?.time_out && (
                        <div className="labelReport">
                          {t("visit_checkout")}
                          {Report?.time_out && (
                            <span dir="ltr" className="data">
                              {dayjs(Report?.time_out).format(format.date_time)}
                            </span>
                          )}
                        </div>
                      )}
                    </Col>
                    <Col span={24} lg={24}>
                      <div className="labelReport">
                        {t("report_details")}
                        {Report?.content && (
                          <span dir="auto" className="data">
                            {Report?.content}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col span={24} lg={24}>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        className="labelReport"
                      >
                        {t("time")} :
                        {Report?.time && (
                          <span
                            style={{ marginRight: 5, direction: "ltr" }}
                            className="data"
                          >
                            {Report?.time}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingBlock: "10vh",
          }}
          className="SpinCon"
        >
          <Spin indicator={antIcon} size="large" />
        </div>
      )}
    </Modal>
  );
};

export default OldReportModal;
