import { useInfiniteQuery } from "react-query";
import { FetchNotifications } from "services/notifications";
import { useAppSelector } from "store";
import ApiResult from "utils/api/models/api-result";
import { ApiError } from "utils/api/models/api-error";
import { NotificationModel } from "models/notification";

export default function useNotifications(
  page: any,
  unread: boolean,
  enabled: boolean
) {
  const queryKey = "Notifications";
  return {
    ...useInfiniteQuery<ApiResult<NotificationModel[]>, ApiError>(
      queryKey,
      async ({ pageParam = 1 }) => await FetchNotifications(pageParam, unread),
      {
        staleTime: 30000,
        enabled: enabled,
        keepPreviousData: true,
        refetchOnWindowFocus: true,
        getNextPageParam: (lastPage) =>
          lastPage?.meta?.currentPage! + 1 ?? false,
      }
    ),
  };
}
