import { Suspense } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import PagesRoutes from "routes/pages.routes";

const loading = (
  <div className="loadingCon">
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      size="large"
    />
  </div>
);

const Content = () => {
  return (
    <div className="whiteBox">
      <Suspense fallback={loading}>
        <PagesRoutes />
      </Suspense>
    </div>
  );
};

export default Content;
