import { Form, Input, Modal, Radio, Spin, notification } from "antd";
import CheckoutTransportation from "hooks/query/transportation/CheckoutTransportation";
import useTransportation from "hooks/query/transportation/useTransportation";
import { t } from "i18next";
import { useEffect, useState } from "react";
import useCurrentLocation from "hooks/others/useCurrentLocation";

interface TransportationModalProps {
  isOpen: boolean;
  setIsOpen: any;
  reportId: number;
  ticketId: number;
  transportationStatus: string;
}

const TransportationModal = (props: TransportationModalProps) => {
  const [value, setValue] = useState("");
  const [form] = Form.useForm();

  const Add = CheckoutTransportation();

  const { data, isLoading } = useTransportation();
  const getLocationOnClick = useCurrentLocation();

  const submitFormHandler = async (e: any) => {
    const formData = new FormData();
    const newLocation = await getLocationOnClick();
    formData.append("transportation_id", e.transportation_type);
    formData.append("cost", e.transportation_cost ? e.transportation_cost : 0);
    formData.append(
      "lat",
      newLocation.latitude ? newLocation.latitude.toString() : "0"
    );
    formData.append(
      "lon",
      newLocation.longitude ? newLocation.longitude.toString() : "0"
    );
    Add.mutateAsync({
      ticketId: props.ticketId,
      reportId: props.reportId,
      data: formData,
    }).then(() => {
      props.setIsOpen(false);
      form.resetFields();
      if (props.transportationStatus === "away") {
        notification.open({
          type: "success",
          message: t("away_done"),
          duration: 3,
        });
      } else {
        notification.open({
          type: "success",
          message: t("checkout_trans_and_checkin"),
          duration: 3,
        });
      }
    });
  };

  const selectedType = data?.data.find((item) => item.id === Number(value));

  useEffect(() => {
    form.resetFields(["transportation_cost"]);
  }, [value]);

  return (
    <Modal
      title={t("transportation_info")}
      open={props.isOpen}
      onCancel={() => {
        props.setIsOpen(false);
        form.resetFields();
      }}
      onOk={() => form.submit()}
      confirmLoading={Add.isLoading}
    >
      {!isLoading && data && (
        <Form
          validateMessages={{
            required: t("please_input") + "${label}!",
          }}
          labelCol={{ span: 24 }}
          form={form}
          onFinish={submitFormHandler}
        >
          <Form.Item
            rules={[{ required: true }]}
            required
            label={t("transportation_type")}
            name="transportation_type"
          >
            <Radio.Group
              onChange={(e) => setValue(e.target.value)}
              value={value}
            >
              {data?.data.map((item) => (
                <Radio key={item.id} value={item.id}>
                  {item.name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            rules={[{ required: selectedType?.has_cost }]}
            required
            label={t("cost")}
            name="transportation_cost"
          >
            <Input disabled={!selectedType?.has_cost} type="number" />
          </Form.Item>
        </Form>
      )}
      {isLoading && <Spin spinning={isLoading} />}
    </Modal>
  );
};

export default TransportationModal;
