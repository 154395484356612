/* eslint-disable react-hooks/exhaustive-deps */
import { EyeFilled, SyncOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Row, Table, Modal } from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PermissionsGate, { hasPermission } from "components/permissionsGate";
import ClientRequestsFillters, {
  filters,
} from "components/filters/clientRequestsFillters";
import PlansTable from "components/table/PlansTable";
import { Roles, Ticket, TicketKeyName } from "utils/helpers/static_data";
import { StatusColor } from "utils/helpers/ticket_status";
import format from "utils/helpers/date-format";
import dayjs from "dayjs";
import { useAppSelector } from "store";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import { UserModel } from "models/users";
import { ColumnsType } from "antd/lib/table/interface";
import usePlans from "hooks/query/plan/usePlans";
import PageTitle from "components/UI/PageTitle";
import useKey from "hooks/others/useKey";
import AddTicketModal from "components/Modals/addTicketModal";
import useProgressLoading from "hooks/others/useProgressLoading";
import i18next, { t } from "i18next";
import ScheduledAppointments from "pages/technicians/ScheduledAppointments";
import AddPlanModal from "components/Modals/addPlanModal";
import { PlanModel } from "models/plan";
import PlansFilters from "components/filters/plansFilters";
import PlanDetailsModal from "components/Modals/planDetailsModal";

const afterMonth = dayjs().add(1, "month").format("YYYY-MM-DD");
const today = dayjs().format("YYYY-MM-DD");
const defaultDateRange = `${today},${afterMonth}`;

let CurrentPage = 1;
let CurrentFilters: filters & {
  DateRange: string;
  SearchText: string;
  SearchBy: string;
} = {
  Status: Ticket.create,
  Sort: "",
  Priority: "-1",
  isActive: 0,
  myClientRequests: 0,
  DateRange: defaultDateRange,
  SearchText: "",
  SearchBy: "ticket_id",
};

/**
 * show all tickets in table
 */

const Plans = () => {
  const location = useLocation();
  const [Page, setPage] = useState(1);
  const [SearchText, setSearch] = useState<string>("");
  const [SearchBy, setSearchBy] = useState<string>("ticket_id"); //default search by
  const [isActive, setIsActive] = useState<filters["isActive"]>(0);

  const [myClientRequests, setMyClientRequests] =
    useState<filters["myClientRequests"]>(0);
  const [Status, setStatus] = useState<filters["Status"]>(
    hasPermission(["browse_tickets"])
      ? Ticket.create
      : hasPermission(["browse_ticket_pending"])
      ? Ticket.pending
      : hasPermission(["browse_ticket_priced"])
      ? Ticket.priced
      : hasPermission(["browse_ticket_active"])
      ? Ticket.active
      : hasPermission(["browse_ticket_done"])
      ? Ticket.done
      : hasPermission(["browse_ticket_approved"])
      ? Ticket.approved
      : hasPermission(["browse_ticket_paid"])
      ? Ticket.paid
      : hasPermission(["browse_ticket_denied"])
      ? Ticket.denied
      : Ticket.create
  );
  const [Priority, setPriority] = useState<filters["Priority"]>("-1");
  const [DateRange, setDateRange] = useState(defaultDateRange);
  const [Sort, setSort] = useState<filters["Sort"]>("");
  const [isDone, setisDone] = useState<boolean>(false);

  const [ticketId, setTicketId] = useState<string>("");

  const [expandedRowKey, setExpandedRowKey] = useState(null);

  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
  }, [UserData.Decrypt]);

  const [ticketsReqEnabled, setTicketReqEnabled] = useState<boolean>(false);

  const [addPlanModalOpen, setAddPlanModalOpen] = useState(false);
  const [planDetailsOpen, setPlanDetailsOpen] = useState(false);

  const { data, isLoading, isFetching, refetch } = usePlans(
    Page,
    Status!,
    SearchText,
    "",
    "",
    isDone
  );

  const progLoading = useProgressLoading({ isFetching });

  const { dataState: clientRequests } = useKey(data?.data);

  const columns: ColumnsType<PlanModel> = [
    {
      title: t("plan_id"),
      dataIndex: ["id"],
      render: (data: any, obj: PlanModel) => <div>{obj.id}</div>,
    },
    {
      title: t("plan_creator"),
      dataIndex: "created_by",
      render: (created_by: PlanModel["created_by"]) => (
        <div>
          {created_by?.full_name !== null &&
            created_by?.full_name !== undefined &&
            created_by?.full_name}
        </div>
      ),
    },
    {
      title: t("start_date"),
      dataIndex: "start_date",
      render: (start_date) => <span>{start_date}</span>,
    },
    {
      title: t("end_date"),
      dataIndex: "end_date",
      render: (end_date) => <span>{end_date}</span>,
    },
    {
      title: t("total_tickets"),
      dataIndex: "",
      render: (data: PlanModel) => (
        <span>
          {data.visits_number}/{data.total_visits}
        </span>
      ),
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (status: any, data: PlanModel) => (
        <div
          className={`statusBtn ${
            data.is_complete ? "status" + Ticket.done : "status" + status.id
          }`}
          style={{ textTransform: "capitalize" }}
        >
          {data.is_complete ? t("done") : data.status.status}
        </div>
      ),
    },
    {
      title: <span className="tickets_count">{t("plan_details")}</span>,
      dataIndex: ["id"],
      render: (id: any, obj: PlanModel) => (
        <PermissionsGate scopes={["read_tickets"]}>
          <div
            className="ticketLink pointer"
            onClick={(e) => {
              e.stopPropagation();
              setTicketId(id);
              setPlanDetailsOpen(true);
            }}
          >
            <span className={"seeTicket"}>
              <EyeFilled />
            </span>
          </div>
        </PermissionsGate>
      ),
    },
    Table.EXPAND_COLUMN,
  ];

  const deviceColumns: ColumnsType<PlanModel> = [
    {
      title: "Client Requests",
      render: (obj: PlanModel) => {
        return (
          <div
            style={{
              border: `3px solid ${StatusColor(obj.status.id)}`,
              padding: "0",
            }}
            className="mobileCard"
          >
            <div className="label">
              <>
                <div
                  className={"statusBtn status" + obj.status.id}
                  style={{
                    borderRadius: "0 0 50% 50%",
                    textTransform: "capitalize",
                  }}
                >
                  {Ticket.paid === obj.status.id.toString() &&
                  User?.role_id === Roles.client
                    ? TicketKeyName.paidClient
                    : obj.status.status}
                </div>
              </>
            </div>
            <Row style={{ padding: "20px", paddingTop: "0" }}>
              <Col md={12} span={24}>
                <div className="label">
                  <span>{t("plan_id")}</span>
                  <span
                    style={{
                      backgroundColor: `${StatusColor(obj.status.id)}77`,
                      width: "fit-content",
                      padding: "7px",
                      borderRadius: "50%",
                    }}
                  >
                    {obj.id}
                  </span>
                </div>
              </Col>
              <Col md={12} span={24}>
                <div className="label">
                  <span> {t("plan_creator")} :</span>
                  {obj.created_by?.full_name !== null &&
                    obj.created_by?.full_name !== undefined &&
                    obj.created_by?.full_name}
                </div>
              </Col>
              <Col md={12} span={24}>
                <div className="label">
                  <span> {t("start_date")} :</span>
                  <span dir="ltr">
                    {dayjs(obj?.start_date).format(format.date_time)}
                  </span>
                </div>
              </Col>
              <Col md={12} span={24}>
                <div className="label">
                  <span> {t("start_date")} :</span>
                  <span dir="ltr">
                    {dayjs(obj?.end_date).format(format.date_time)}
                  </span>
                </div>
              </Col>

              <Col md={12} span={24}>
                <div className="label">
                  <span> {t("tickets_count")} :</span>
                  <span style={{ textAlign: "center" }}>
                    {obj.visits_number}
                  </span>
                </div>
              </Col>
              <Col span={24}>
                <PermissionsGate scopes={["read_tickets"]}>
                  <div
                    className="ticketLink pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setTicketId(obj?.id.toString());
                      setPlanDetailsOpen(true);
                    }}
                  >
                    <span className={"seeTicket"}>
                      <EyeFilled />
                    </span>
                  </div>
                </PermissionsGate>
              </Col>
            </Row>
          </div>
        );
      },
    },
  ];
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    CurrentPage = 1;
    CurrentFilters = {
      Status: Ticket.create,
      Sort: "",
      Priority: "-1",
      isActive: 0,
      myClientRequests: 0,
      DateRange: defaultDateRange,
      SearchText: "",
      SearchBy: "ticket_id",
    };
  }, [isLoggedIn, location.pathname]);

  useEffect(() => {
    setPage(CurrentPage);
  }, [
    Status,
    Priority,
    DateRange,
    SearchText,
    isActive,
    myClientRequests,
    Sort,
  ]);

  useEffect(() => {
    setStatus(CurrentFilters.Status);
    setSort(CurrentFilters.Sort);
    setPriority(CurrentFilters.Priority);
    setIsActive(CurrentFilters.isActive);
    setMyClientRequests(CurrentFilters.myClientRequests);
    setDateRange(CurrentFilters.DateRange);
    setSearch(CurrentFilters.SearchText);
    setSearchBy(CurrentFilters.SearchBy);
  }, [location]);

  const changePage = (e: any) => {
    setPage(e);
    CurrentPage = e;
  };

  const onExpand = (expanded: boolean, record: any) => {
    if (expanded) {
      setExpandedRowKey(record.key);
    } else {
      setExpandedRowKey(null);
    }
    setTicketId(record.id);
    setTicketReqEnabled(true);
  };

  useEffect(() => {
    setExpandedRowKey(null);
  }, [Status, Sort, SearchText, DateRange, Page, isActive]);

  return (
    <div className="tickets">
      <PageTitle isAbsolute={true}>{t("plans_menu")}</PageTitle>
      <div
        style={{
          justifyContent: "end",
        }}
        className="altTicketBtns"
      >
        <div className="actionBtns">
          <Button
            onClick={() => refetch?.()}
            type="primary"
            className="loadingBtn"
          >
            <SyncOutlined spin={isFetching} />
          </Button>
          <PermissionsGate scopes={["add_plans"]}>
            <Button
              onClick={() => setAddPlanModalOpen(true)}
              style={{ whiteSpace: "nowrap" }}
              type="primary"
            >
              {t("add_plan")}
            </Button>
          </PermissionsGate>
        </div>
      </div>

      <PlansFilters
        Status={Status}
        isDone={isDone}
        setIsDone={setisDone}
        SearchText={SearchText}
        setStatus={(status: filters["Status"]) => {
          CurrentPage = 1;
          setStatus(status);
          CurrentFilters.Status = status;
        }}
        setSearch={(search: string) => {
          CurrentPage = 1;
          setSearch(search);
          CurrentFilters.SearchText = search;
        }}
        setDate={(date: any) => {
          CurrentPage = 1;
          setDateRange(date);
          CurrentFilters.DateRange = date;
        }}
      />
      <PlansTable
        isLoading={isLoading}
        columns={columns}
        mobileColumns={deviceColumns}
        dataSource={clientRequests}
        page={(e) => changePage(e)}
        current={Page}
        totalPage={data && data.meta && (data?.meta?.total as any)}
        isExpandable={true}
        onExpand={onExpand}
        expandedRowKey={expandedRowKey}
        ticketId={ticketId}
        ticketStatus={Status}
        enabled={ticketsReqEnabled}
        linearLoading={progLoading}
      />

      <AddPlanModal isOpen={addPlanModalOpen} setIsOpen={setAddPlanModalOpen} />
      {ticketId && data?.data && (
        <PlanDetailsModal
          open={planDetailsOpen}
          closeModal={() => setPlanDetailsOpen(false)}
          plan={data?.data.filter((plan: any) => plan.id == ticketId)[0]!}
        />
      )}
    </div>
  );
};

export default Plans;
