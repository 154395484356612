import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { notification } from "antd";
import { isError } from "utils/api/models/api-response";
import { DeleteCompanyPlaceAsync } from "services/places";
import i18next from "i18next";

export default function DeletePlace() {
  const queryClient = useQueryClient();

  return {
    ...useMutation(DeleteCompanyPlaceAsync, {
      onSuccess: async (res) => {
        if (!isError(res)) {
          notification.open({
            message:
              i18next.language === "ar"
                ? "تم حذف الفرع بنجاح"
                : "Place has been deleted successfully",
          });
          queryClient.invalidateQueries("Places");
          queryClient.invalidateQueries("Place");
        }
      },
    }),
  };
}
