import { notification } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Location {
  latitude: number | null;
  longitude: number | null;
  error: string | null;
}

const useCurrentLocation = (): (() => Promise<Location>) => {
  const { t } = useTranslation();

  const getLocationOnClick = (): Promise<Location> => {
    return new Promise<Location>((resolve, reject) => {
      const handleSuccess = (position: GeolocationPosition) => {
        const newLocation = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          error: null,
        };
        console.log("Geolocation success:", newLocation);
        resolve(newLocation);
      };

      const handleError = (error: GeolocationPositionError) => {
        const newLocation = {
          latitude: null,
          longitude: null,
          error: error.message,
        };
        console.error("Geolocation error:", error);
        if (error.code === 1) {
          notification.open({
            type: "warning",
            message: t("please_accept_location_pirmission"),
            duration: 3,
          });
        } else {
          notification.open({
            type: "warning",
            message: t("please_install_app_to_get_location"),
            duration: 7,
          });
        }
        reject(newLocation);
      };

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(handleSuccess, handleError, {
          timeout: 30000,
          enableHighAccuracy: true,
          maximumAge: 75000,
        });
      } else {
        const newLocation = {
          latitude: null,
          longitude: null,
          error: "Geolocation is not supported by your browser.",
        };
        console.error("Geolocation not supported");
        reject(newLocation);
      }
    });
  };

  return getLocationOnClick;
};

export default useCurrentLocation;
