import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { isError } from "utils/api/models/api-response";
import { AddCheckoutReport } from "services/reports";

export default function CheckoutReport() {
  const queryClient = useQueryClient();
  return {
    ...useMutation(AddCheckoutReport, {
      onSuccess: async (res) => {
        if (!isError(res)) {
          queryClient.invalidateQueries("ticket");
          queryClient.invalidateQueries("Reports");
          queryClient.invalidateQueries("myPlan");
        }
      },
    }),
  };
}
