import { CaretDownOutlined } from "@ant-design/icons";
import { Button, Col, Popconfirm, Row, Table } from "antd";
import { ColumnType } from "antd/es/table";
import AddTicketServiceModal from "components/Modals/categoriesModals/addServiceModal";
import EditSubCategoryModal from "components/Modals/categoriesModals/updateServiceModal";
import useLevels from "hooks/query/levels/useLevels";
import { TicketServiceModel } from "models/ticketServices";
import { ChangeEvent, useEffect, useState } from "react";
import { useWidth } from "utils/helpers/use-is-mobile";

import useServicesByLevel from "hooks/query/services/useServicesByLevel";
import useServicesByCategory from "hooks/query/services/useServicesByCategory";
import { useCategoriesList } from "hooks/query/categories/useCategories";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import { UserModel } from "models/users";
import { Roles } from "utils/helpers/static_data";
import DeleteService from "hooks/query/services/deleteService";
import Color from "utils/helpers/export-sass-colors";
import TableFilters, {
  TableFiltersProps,
} from "components/filters/TableFilters";
import { hasPermission } from "components/permissionsGate";
import i18next, { t } from "i18next";

const CPServices = () => {
  const { isMobile } = useWidth();
  const [levelId, setLevelId] = useState<string>("");
  const [categoryId, setCategoryId] = useState<string>("88");
  const [pagination, setPagination] = useState<{
    page: number;
    perPage: number;
  }>({ page: 1, perPage: 8 });

  const [servicesBy, setServicesBy] = useState("category");

  const [User, setUser] = useState<UserModel | null>(null);

  const { data: levels } = useLevels(1, 8);
  const { data: categories } = useCategoriesList();
  const {
    data: servicesByCategory,
    isLoading: catsSrvsLoading,
    isFetching: catsSrvsFetching,
  } = useServicesByCategory(
    +categoryId,
    pagination.page,
    pagination.perPage,
    servicesBy === "category"
  );
  const {
    data: servicesByLevel,
    isLoading,
    isFetching,
  } = useServicesByLevel(
    +levelId,
    pagination.page,
    pagination.perPage,
    servicesBy === "level"
  );

  useEffect(() => {
    setPagination({
      page: 1,
      perPage: 8,
    });
  }, [servicesBy, levelId, categoryId]);

  const deleteService = DeleteService();

  const services =
    servicesBy === "level" ? servicesByLevel : servicesByCategory;

  const columns: ColumnType<TicketServiceModel>[] = [
    {
      title: t("arabic_name"),
      dataIndex: "name_ar",
      align: "center",
      width: "25%",
    },
    {
      title: t("english_name"),
      dataIndex: "name_en",
      align: "center",
      width: "25%",
    },
    {
      title: "",
      dataIndex: "id",
      align: "center",
      width: 200,
      render(value, record) {
        //NOTE - edit & delete permission
        return (
          <>
            {!isFetching &&
              !catsSrvsFetching &&
              hasPermission(["edit_categories"]) && (
                <EditSubCategoryModal key={value} item={record} />
              )}
            {!isFetching &&
              !catsSrvsFetching &&
              hasPermission(["delete_categories"]) && (
                <Popconfirm
                  okType="danger"
                  title={
                    i18next.language === "ar"
                      ? "هل انت متأكد من الحذف؟"
                      : "Are you sure to delete?"
                  }
                  onConfirm={() => {
                    return new Promise((resolve, reject) => {
                      deleteService
                        .mutateAsync({ id: value })
                        .then(() => {
                          resolve(null);
                        })
                        .catch(() => {
                          reject();
                        });
                    });
                  }}
                >
                  <Button danger type="link">
                    {t("delete")}
                  </Button>
                </Popconfirm>
              )}
          </>
        );
      },
    },
  ];

  const deviceColumns: ColumnType<TicketServiceModel>[] = [
    {
      title: "",
      render: (obj: TicketServiceModel) => {
        return (
          <div
            style={{
              padding: "0",
              borderRadius: "18px",
              overflow: "hidden",
              border: `2px solid ${Color.blue}`,
              width: "90%",
              margin: "auto",
            }}
          >
            <Row style={{ padding: "20px" }}>
              <Col md={12} span={24}>
                <div className="label" style={{ marginBottom: "4px" }}>
                  <span style={{ display: "block" }}>{t("arabic_name")}: </span>
                  {obj.name_ar}
                </div>
              </Col>
              <Col md={12} span={24}>
                <div className="label" style={{ marginBottom: "4px" }}>
                  <span style={{ display: "block" }}>
                    {t("english_name")}:{" "}
                  </span>
                  {obj.name_en}
                </div>
              </Col>

              <Col md={12} span={24}>
                {!isFetching &&
                  !catsSrvsFetching &&
                  hasPermission(["edit_categories"]) && (
                    <EditSubCategoryModal key={obj.id} item={obj} />
                  )}
                {!isFetching &&
                  !catsSrvsFetching &&
                  hasPermission(["delete_categories"]) && (
                    <Popconfirm
                      okType="danger"
                      title={
                        i18next.language === "ar"
                          ? "هل انت متأكد من الحذف؟"
                          : "Are you sure to delete?"
                      }
                      onConfirm={() => {
                        return new Promise((resolve, reject) => {
                          deleteService
                            .mutateAsync({ id: obj.id })
                            .then(() => {
                              resolve(null);
                            })
                            .catch(() => {
                              reject();
                            });
                        });
                      }}
                    >
                      <Button danger type="link">
                        {t("delete")}
                      </Button>
                    </Popconfirm>
                  )}
              </Col>
            </Row>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setUser(UserData.Decrypt());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserData.Decrypt]);

  useEffect(() => {
    setCategoryId(categories?.data[0]?.id.toString()!);
  }, [categories]);

  let levelsOptions: any[] = [];
  let categoriesOptions: any[] = [];

  if (levels?.data) {
    for (const level of levels?.data!) {
      const updatedItem = {
        value: level.id.toString(),
        name: level.name,
      };
      levelsOptions.push(updatedItem);
    }
  }

  if (categories?.data) {
    for (const category of categories?.data!) {
      const updatedItem = {
        value: category.id.toString(),
        name: category.name,
      };
      categoriesOptions.push(updatedItem);
    }
  }

  const filtersConfig: TableFiltersProps[] = [
    {
      elementType: "radio",
      label: i18next.language === "ar" ? "الخدمات حسب" : "Services about",
      elementConfig: {
        onChange: (e: ChangeEvent<HTMLInputElement>) =>
          setServicesBy(e.target.value),
        value: servicesBy,
      },
      options: [
        { value: "level", name: t("level") },
        { value: "category", name: t("category_name") },
      ],
    },
    {
      elementType: "select",
      label: servicesBy === "level" ? t("level_type") : t("category_type"),
      elementConfig: {
        onChange:
          servicesBy === "level"
            ? (e: string) => setLevelId(e)
            : (e: string) => setCategoryId(e),
        value: servicesBy === "level" ? levelId : categoryId,
        suffixIcon: (
          <CaretDownOutlined
            className="ant-select-suffix"
            style={{ color: "black" }}
          />
        ),
      },
      options: servicesBy === "level" ? levelsOptions : categoriesOptions,
    },
  ];

  return (
    <div className="categories">
      <div>
        {hasPermission(["add_categories"]) && (
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <AddTicketServiceModal category={levelId} />
          </div>
        )}
      </div>
      <Table
        bordered
        columns={isMobile ? deviceColumns : columns}
        dataSource={services?.data}
        loading={isLoading || isFetching || catsSrvsLoading || catsSrvsFetching}
        pagination={{
          current: pagination.page,
          pageSize: pagination.perPage,
          total: services?.meta.total,
          onChange(page, pageSize) {
            setPagination({
              page,
              perPage: 8,
            });
          },
          style: {
            justifyContent: "flex-start",
          },
        }}
      />
    </div>
  );
};
export default CPServices;
