const Footer = () => {
  return (
    <div className="footer">
      <div className="version">Version {process.env.REACT_APP_VERSION}</div>
      <div className="copyright">
        <a href={"https://Wareed.com/"} rel="noreferrer" target={"_blank"}>
        Wareed
        </a>{" "}
        2024©
      </div>
    </div>
  );
};

export default Footer;
