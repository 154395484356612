import { Button, Form, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import RejectSchedule from "hooks/query/schedule/rejectSchedule";
import { useWidth } from "utils/helpers/use-is-mobile";
import { Roles, Ticket } from "utils/helpers/static_data";
import { hasSomePermission } from "components/permissionsGate";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import { UserModel } from "models/users";
import { TicketModel } from "models/tickets";
import { filters } from "components/filters/ticketsFillters";
import dayjs from "dayjs";
import format from "utils/helpers/date-format";
import { ClockCircleTwoTone } from "@ant-design/icons";
import i18next, { t } from "i18next";

const Schedule = ({
  ticket,
  Status,
}: {
  ticket?: TicketModel;
  Status: filters["Status"];
}) => {
  const { isMobile } = useWidth();
  const [IsModalScheduleReject, setIsModalScheduleReject] = useState(false);
  const [rejectForm] = Form.useForm();

  const Reject = RejectSchedule();

  const reject = (e: any) => {
    const formData = new FormData();
    formData.append("notes", e.notes);
    formData.append("_method", "PUT");
    Reject.mutateAsync({
      id: ticket?.start_schedule?.id,
      data: formData,
    }).then(() => closeScheduleRejectModal());
  };

  const closeScheduleRejectModal = () => {
    setIsModalScheduleReject(false);
  };

  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserData.Decrypt]);
  const client = User?.role_id === Roles.client;

  return (
    <>
      <div className="acitvationInfo">
        {ticket?.start_schedule !== null &&
          ticket?.start_schedule?.status?.key === "schedule_set" &&
          Status === Ticket.active && (
            <div className="ticketTime">
              {t("estimated_time_to_arrive")}
              <span dir="ltr">
                {dayjs(ticket?.start_schedule?.time).format(format.date_time)}
              </span>
              <ClockCircleTwoTone className="icon" />
            </div>
          )}
      </div>
      {hasSomePermission(["add_ticket_reports", "edit_ticket_reports"]) &&
        ticket?.start_schedule !== null &&
        ticket?.start_schedule?.status?.key === "schedule_rejected" &&
        Status === Ticket.active && (
          <div
            className="time"
            style={
              isMobile
                ? {
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }
                : {}
            }
          >
            {t("reject_reason")} : <span>{ticket?.start_schedule?.notes}</span>
          </div>
        )}
      {client &&
        ticket?.start_schedule !== null &&
        ticket?.start_schedule?.status?.key === "schedule_set" &&
        Status === Ticket.active && (
          <Button
            className="addRwview reject"
            key="reject"
            type="default"
            onClick={() => setIsModalScheduleReject(true)}
          >
            {t("reject_schedule")}
          </Button>
        )}
      <Modal
        key={"scheduleRejectModal"}
        centered
        title={t("reject_schedule")}
        onCancel={() => closeScheduleRejectModal()}
        open={IsModalScheduleReject}
        footer={[
          <>
            <Button
              className="addRwview reject"
              loading={Reject.isLoading}
              onClick={() => rejectForm.submit()}
              type="default"
            >
              {t("send") + " " + t("reject_reason")}
            </Button>
            <Button
              className="closeBtn"
              onClick={() => closeScheduleRejectModal()}
            >
              {t("cancel")}
            </Button>
          </>,
        ]}
      >
        <Form form={rejectForm} onFinish={(e: any) => reject(e)}>
          <Form.Item
            name="notes"
            rules={[
              {
                required: true,
                message:
                  i18next.language === "ar"
                    ? "يرجى ادخال الملاحظة!"
                    : "Please input note",
              },
            ]}
            className="notesArea"
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Schedule;
