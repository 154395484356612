import { InfoCircleTwoTone } from "@ant-design/icons";
import { Button, Modal } from "antd";
import axios from "axios";
import { useWidth } from "utils/helpers/use-is-mobile";
import { Roles } from "utils/helpers/static_data";
import { useEffect, useState } from "react";
import { UserModel } from "models/users";
import { UserData } from "utils/helpers/encrypt-decrypt-user";

type user_role = typeof Roles;
interface text {
  title: string;
  list: Array<string>;
  role: Array<keyof user_role> | "all";
}
interface content {
  show: boolean;
  header: string;
  text: Array<text>;
}

let Content: Array<content> = [];

axios.get(`/new-updates.json`).then((res: any) => {
  Content = res.data;
  let hasContentToShow: boolean = false;
  Content.forEach(({ show }) => {
    if (show) {
      hasContentToShow = true;
    }
  });
  if (!hasContentToShow) {
    Content = [];
  }
});

export const NewsIcon = (props: any) => {
  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserData.Decrypt]);

  Content.forEach(({ text }, contentIndex) => {
    text?.forEach(({ role }, index) => {
      let found = false;
      if (typeof role === "string" && role === "all") {
        found = true;
      } else if (role instanceof Array && role.length > 0) {
        role.forEach((value) => {
          if (Roles[value] === User?.role_id) {
            found = true;
            return;
          }
        });
      }
      if (!found) {
        Content[contentIndex].text.splice(index, 1);
      }
    });
    if (Content[contentIndex].text.length === 0) {
      Content.splice(contentIndex, 1);
    }
  });

  return (
    <>
      {Content.length ? (
        <div className="whatsnew">
          <InfoCircleTwoTone
            style={{ fontSize: "20px" }}
            onClick={(e) => props.onClick(e)}
            title={"What's New"}
          />
          <span className="text">التحديثات</span>
        </div>
      ) : null}
    </>
  );
};

const NewsModal = (props: any) => {
  const { isMobile } = useWidth();
  return (
    <>
      <Modal
        key={"newsModal"}
        width={isMobile ? "100%" : "50%"}
        centered
        title={"التحديثات"}
        bodyStyle={{ height: "70vh", overflowY: "auto" }}
        onCancel={props.close}
        open={props.modal}
        className={"newsModal"}
        footer={[
          <Button
            type="primary"
            onClick={() => props.close()}
            className="closeBtn"
            key="back"
          >
            اغلاق
          </Button>,
        ]}
      >
        {Content.map(({ header, text, show }, index) =>
          show ? (
            <p
              style={{ textAlign: "right", direction: "rtl", padding: "20px" }}
              key={index}
            >
              <h2>{header}</h2>
              <div>
                <ul>
                  {text?.map(({ title, list }, index) => (
                    <>
                      <li>
                        <h3>{title}:</h3>
                        <ul>
                          {list?.map((tip, index) => (
                            <li key={index}>{tip}</li>
                          ))}
                        </ul>
                      </li>
                      <br />
                    </>
                  ))}
                </ul>
              </div>
            </p>
          ) : (
            <></>
          )
        )}
      </Modal>
    </>
  );
};

export default NewsModal;
