/**
 * modal to show time line of the Technical checkin and checkout location on the map
 */

import dayjs from "dayjs";
import format from "utils/helpers/date-format";
import { ReportModel, TransportationReportsModel } from "models/reports";
import { t } from "i18next";

const ModalMap = (props: {
  type: "in" | "out" | "go-in" | "go-out" | "away-in" | "away-out";
  data?: ReportModel;
  goReport?: TransportationReportsModel;
  awayReport?: TransportationReportsModel;
}) => {
  let title;
  let dataContent;

  if (props.type === "in") {
    title = t("visit_checkin");
    dataContent = (
      <span>
        {props.data?.time_in !== null && (
          <span dir="ltr" className="data">
            {dayjs(props?.data?.time_in).format(format.date_time)}
          </span>
        )}
      </span>
    );
  } else if (props.type === "out") {
    title = t("finish_report");
    dataContent = (
      <span>
        {props.data?.time_out !== null && (
          <span dir="ltr" className="data">
            {dayjs(props?.data?.time_out).format(format.date_time)}
          </span>
        )}
      </span>
    );
  } else if (props.type === "go-in") {
    title = t("go_in");
    dataContent = (
      <span>
        {props.goReport && (
          <span dir="ltr" className="data">
            {dayjs(props?.goReport.time_in).format(format.date_time)}
          </span>
        )}
      </span>
    );
  } else if (props.type === "go-out") {
    title = t("go_out");
    dataContent = (
      <span>
        {props.goReport && (
          <span dir="ltr" className="data">
            {dayjs(props?.goReport.time_out).format(format.date_time)}
          </span>
        )}
      </span>
    );
  } else if (props.type === "away-in") {
    title = t("away_in");
    dataContent = (
      <span>
        {props.awayReport && (
          <span dir="ltr" className="data">
            {dayjs(props?.awayReport.time_in).format(format.date_time)}
          </span>
        )}
      </span>
    );
  } else if (props.type === "away-out") {
    title = t("away_out");
    dataContent = (
      <span>
        {props.awayReport && (
          <span dir="ltr" className="data">
            {dayjs(props?.awayReport.time_out).format(format.date_time)}
          </span>
        )}
      </span>
    );
  }

  return (
    <div className="modalMap">
      <div className="timeLabel">
        <span>{title}: </span>
        {dataContent}
      </div>
    </div>
  );
};

export default ModalMap;
