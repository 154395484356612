import useDebounce from "hooks/others/useDebounce";
import { PlanModel } from "models/plan";
import { useQuery } from "react-query";
import { FetchPlans } from "services/plan";
import { ApiError } from "utils/api/models/api-error";
import ApiResult from "utils/api/models/api-result";
// import { useAppSelector } from "store";

export default function usePlans(
  page: number,
  status: string,
  created_by: string,
  start_date: string,
  end_date: string,
  isDone: boolean
) {
  const { newParam: searchParam } = useDebounce(created_by);
  const queryKey = [
    "plans",
    page,
    status,
    searchParam,
    start_date,
    end_date,
    isDone,
  ];
  return {
    ...useQuery<ApiResult<PlanModel[]>, ApiError>(
      queryKey,
      () => FetchPlans(page, status, searchParam, start_date, end_date, isDone),
      {
        staleTime: 30000,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
      }
    ),
  };
}
