import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Popover, Spin, notification } from "antd";
import { useEffect, useState } from "react";
import PermissionsGate, {
  hasPermission,
  hasSomePermission,
} from "components/permissionsGate";
import useReports from "hooks/query/reports/useReports";
import ReportModal from "components/Modals/reportModal";
import FormalReportModal from "components/Modals/formalReportModal";
import AddSchedule from "hooks/query/schedule/schedule";
import { useWidth } from "utils/helpers/use-is-mobile";
import { Roles, Ticket } from "utils/helpers/static_data";
import Color from "utils/helpers/export-sass-colors";
import CardReports from "./cardReport";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import { UserModel } from "models/users";
import Settings from "utils/helpers/Settings";
import TransportationModal from "./transportaionModal";
import { filters } from "components/filters/clientRequestsFillters";
import { TicketModel } from "models/tickets";
import useProgressLoading from "hooks/others/useProgressLoading";
import CheckinReport from "hooks/query/reports/CheckinReports";
import CheckinTransportation from "hooks/query/transportation/CheckinTransportation";
import i18next, { t } from "i18next";
import useCurrentLocation from "hooks/others/useCurrentLocation";

/**
 * show all reports of Ticket
 */

interface ReportsModalProps {
  isLeader: boolean | undefined;
  Status: filters["Status"];
  id: TicketModel["id"];
  schedule: TicketModel["start_schedule"];
  modal: boolean;
  close: any;
  checkType: TicketModel["check_type"];
  active: TicketModel["currently_active"];
  trCheckType: TicketModel["transportation_check_type"];
  transportationStatus: TicketModel["transportation_status"];
}

const ReportsModal = (props: ReportsModalProps) => {
  const [IsModalReport, setIsModalReport] = useState(false);
  const [IsModalFormal, setIsModalFormal] = useState(false);
  const [IsModalSchedule, setIsModalSchedule] = useState(false);
  const [isTransportaionModal, setIsTransportationModal] = useState(false);
  const [ReportId, setReportId] = useState(0);
  const [ReportsCollapse, setReportsCollapse] = useState<number>(
    Settings.get("reportsCollapse")
  );
  const ReportsData = useReports(props.id, props.modal);
  const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
  const Add = CheckinReport();
  const addSchedule = AddSchedule();
  const { isMobile } = useWidth();

  const addTranspReport = CheckinTransportation();
  const [popoverIsVisible, setPopOverIsVisible] = useState(false);
  const [popoverReportIsVisible, setPopOverReportIsVisible] = useState(false);
  const [isUserDeniedGeo, setIsUserDeniedGeo] = useState(false);

  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserData.Decrypt]);

  const [form] = Form.useForm();

  const progLoading = useProgressLoading({
    isFetching: ReportsData.isFetching,
  });

  const CloseReportsModal = () => {
    props.close();
  };
  const closeScheduleModal = () => {
    setIsModalSchedule(false);
  };

  useEffect(() => {
    Settings.set("reportsCollapse", +ReportsCollapse);
  }, [ReportsCollapse]);

  const openModel = (id: any) => {
    setIsModalReport(true);
    setReportId(id);
  };

  const openTransporationModal = (id: any) => {
    setIsTransportationModal(true);
    setReportId(id);
  };

  useEffect(() => {
    if (props.modal && !ReportsData.isLoading) {
      ReportsData.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, props.modal]);

  const getLocationOnClick = useCurrentLocation();

  const checkin = async () => {
    const formData = new FormData();
    try {
      const newLocation = await getLocationOnClick();
      console.log("my new location:", newLocation);
      const { latitude, longitude } = newLocation;
      if (latitude !== null && longitude !== null) {
        formData.append("lat", latitude.toString());
        formData.append("lon", longitude.toString());
        console.log("FormData:", formData);
        await Add.mutateAsync({ id: props.id, data: formData });
        notification.open({
          type: "success",
          message: t("visit_checked_in_successfully"),
        });
      } else {
        console.log("Location not obtained:", newLocation.error);
        notification.open({
          type: "warning",
          message: t("please_install_app_to_get_location"),
          duration: 7,
        });
      }
    } catch (error) {
      console.log("Error during checkin:", error);
      notification.open({
        type: "warning",
        message: t("please_install_app_to_get_location"),
        duration: 7,
      });
    }
  };

  const schedule = (e: any) => {
    const formData = new FormData();
    formData.append("time", e.time.replace("T", " "));
    addSchedule
      .mutateAsync({ id: props.id, data: formData })
      .then(() => closeScheduleModal());
  };

  const addTransportationReport = async () => {
    const formData = new FormData();
    const newLocation = await getLocationOnClick();
    const { latitude, longitude } = newLocation;
    if (latitude !== null && longitude !== null) {
      formData.append("lat", latitude.toString());
      formData.append("lon", longitude.toString());
      addTranspReport.mutateAsync({ id: props.id, data: formData }).then(() =>
        notification.open({
          type: "success",
          message: t("transportation_started_successfully"),
        })
      );
    } else {
      notification.open({
        type: "warning",
        message: t("please_install_app_to_get_location"),
        duration: 7,
      });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setPopOverIsVisible(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [popoverIsVisible]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setPopOverReportIsVisible(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [popoverReportIsVisible]);

  const client = User?.role_id === Roles.client;
  const accountant = User?.role_id === Roles.accountant;
  const sales = User?.role_id === Roles.sales;

  let canCheckIn: boolean = true;
  if (ReportsData.data?.data) {
    for (const report of ReportsData?.data?.data!) {
      if (report.checkin_user.id === User?.id) {
        if (report.time_out === null) {
          canCheckIn = false;
        }
        if (report.transportation_reports !== null) {
          for (const transportationReport of report.transportation_reports) {
            if (transportationReport.time_out === null) {
              canCheckIn = false;
            }
          }
        }
      }
    }
  }


  return (
    <>
      <Modal
        key={"reportsModal"}
        width={!!ReportsCollapse ? "90%" : 850}
        onCancel={() => CloseReportsModal()}
        title={t("technical_reports")}
        open={props.modal}
        closeIcon={null}
        bodyStyle={{ padding: "0 24px" }}
        footer={[
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <Button
              type="primary"
              onClick={() => CloseReportsModal()}
              className="closeBtn"
              key="back"
            >
              {t("close")}
            </Button>

            {hasPermission(["edit_ticket_reports"]) &&
              props.Status === Ticket.active && (
                <Button
                  className="closeBtn"
                  key="add"
                  onClick={() => openModel(0)}
                >
                  {t("add_report")}
                </Button>
              )}
            {hasPermission(["add_ticket_reports"]) &&
              props.Status === Ticket.active && (
                <>
                  {canCheckIn && (
                    <>
                      <Popover
                        visible={popoverIsVisible}
                        trigger="click"
                        title={t("cant_start_transportation")}
                        content={
                          <div style={{ textAlign: "center" }}>
                            {t("please_accept_location_pirmission")}
                          </div>
                        }
                      >
                        <Button
                          onClick={
                            !isUserDeniedGeo
                              ? addTransportationReport
                              : () => setPopOverIsVisible((prev) => !prev)
                          }
                          loading={addTranspReport.isLoading}
                          type="primary"
                          key="add"
                          disabled={ReportsData.isFetching}
                        >
                          {t("start_transportation")}
                        </Button>
                      </Popover>
                      <Popover
                        visible={popoverReportIsVisible}
                        trigger="click"
                        title={t("cant_start_report")}
                        content={
                          <div style={{ textAlign: "center" }}>
                            {t("please_accept_location_pirmission")}
                          </div>
                        }
                      >
                        <Button
                          loading={Add.isLoading}
                          key="add"
                          type="primary"
                          onClick={
                            !isUserDeniedGeo
                              ? () => checkin()
                              : () => setPopOverReportIsVisible((prev) => !prev)
                          }
                          disabled={ReportsData.isFetching}
                        >
                          {t("visit_checkin")}
                        </Button>
                      </Popover>
                    </>
                  )}
                </>
              )}
            {hasPermission(["add_ticket_reports"]) &&
              (props?.schedule === null ||
                props?.schedule?.status?.key === "schedule_rejected") &&
              props.Status === Ticket.active &&
              !props.active && (
                <Button
                  className="addRwview"
                  key="start"
                  type="primary"
                  onClick={() => setIsModalSchedule(true)}
                >
                  {t("estimated_time_to_finish")}
                </Button>
              )}
          </div>,
          <>
            {/* <PermissionsGate
              scopes={props.isLeader ? [] : ["edit_ticket_reports"]}
            >
              {props.Status !== Ticket.pending &&
                props.Status !== Ticket.priced &&
                ReportsData.data?.data.length !== 0 &&
                !ReportsData.isLoading && (
                  <Button
                    onClick={() => setIsModalFormal(true)}
                    className="addRwview"
                    type="primary"
                    key="add"
                  >
                    {t("edit_final_report")}
                  </Button>
                )}
            </PermissionsGate> */}
            {/* {(client || accountant || sales) &&
              props.Status !== Ticket.pending &&
              props.Status !== Ticket.priced &&
              props.Status !== Ticket.active && (
                <Button
                  onClick={() => setIsModalFormal(true)}
                  className="addRwview"
                  type="primary"
                  key="add"
                >
                  {t("final_report")}
                </Button>
              )} */}
          </>,
        ]}
        centered
      >
        {ReportsData?.data?.data && !ReportsData.isLoading ? (
          <>
            {ReportsData?.data?.data.length === 0 ? (
              <div
                style={{
                  textAlign: "center",
                  padding: "60px 24px",
                  fontSize: 20,
                }}
              >
                {t("no_report")}
              </div>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      backgroundColor: Color.lightGrey,
                      width: "fit-content",
                      borderRadius: "10px",
                    }}
                  >
                    {t("num_of_technical_reports")}{" "}
                    {ReportsData?.data?.meta.total}
                  </div>
                  {/* <Button
                    type="primary"
                    onClick={() => setPreviousModalOpen(true)}
                  >
                    {t("previous_visits_reports")}
                  </Button> */}
                </div>
                <div
                  style={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    maxHeight: "65vh",
                    padding: "10px",
                    marginBottom: "1rem",
                  }}
                >
                  <>
                    <CardReports
                      Reports={ReportsData.data?.data}
                      openModel={openModel}
                      openTransportationModal={openTransporationModal}
                      Status={props.Status}
                      isUserDeniedGeo={isUserDeniedGeo}
                      isFetching={ReportsData.isFetching}
                    />
                  </>
                </div>
                {ReportsData.data && (
                  <div className="time">
                    {t("total_work_time")} :{" "}
                    <span>{ReportsData?.data?.meta?.other?.total_time}</span>
                  </div>
                )}
              </>
            )}

            {props?.schedule !== null &&
              props?.schedule?.status?.key === "schedule_set" &&
              props?.Status === Ticket.active && (
                <div className="time">
                  {t("estimated_time_to_finish")} :
                  <span>{props?.schedule?.time}</span>
                </div>
              )}
            {hasSomePermission(["add_ticket_reports", "edit_ticket_reports"]) &&
              props?.schedule !== null &&
              props?.schedule?.status?.key === "schedule_rejected" &&
              props?.Status === Ticket.active && (
                <div
                  className="time"
                  style={
                    isMobile
                      ? { alignItems: "flex-start", flexDirection: "column" }
                      : {}
                  }
                >
                  {t("reject_reason_hint")} :{" "}
                  <span>{props?.schedule?.notes}</span>
                </div>
              )}
            {IsModalReport && (
              <ReportModal
                ticketId={props.id}
                modal={IsModalReport}
                id={ReportId}
                Status={props.Status}
                close={() => setIsModalReport(false)}
                isLeader={props.isLeader}
                isUserDeniedGeo={isUserDeniedGeo}
              />
            )}
            <TransportationModal
              isOpen={isTransportaionModal}
              setIsOpen={setIsTransportationModal}
              reportId={ReportId}
              ticketId={props.id}
              transportationStatus={props.transportationStatus}
            />
            {IsModalFormal && (
              <FormalReportModal
                modal={IsModalFormal}
                id={props.id}
                Status={props.Status}
                close={() => setIsModalFormal(false)}
              />
            )}
            <Modal
              key={"scheduleModal"}
              centered
              title={t("update_estimated_time")}
              onCancel={() => closeScheduleModal()}
              open={IsModalSchedule}
              footer={[
                <>
                  <Button
                    className="addRwview"
                    loading={addSchedule.isLoading}
                    onClick={() => form.submit()}
                    type="primary"
                  >
                    {t("send_to_customer")}
                  </Button>
                  <Button
                    className="closeBtn"
                    onClick={() => closeScheduleModal()}
                  >
                    {t("cancel")}
                  </Button>
                </>,
              ]}
            >
              <Form form={form} onFinish={(e: any) => schedule(e)}>
                <Form.Item
                  name="time"
                  rules={[
                    {
                      required: true,
                      message:
                        i18next.language === "ar"
                          ? "يرجى تحديد الوقت!"
                          : "please input time",
                    },
                  ]}
                >
                  <Input type="datetime-local" />
                </Form.Item>
              </Form>
            </Modal>
          </>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              paddingBlock: "10vh",
            }}
            className="SpinCon"
          >
            <Spin indicator={antIcon} size="large" />
          </div>
        )}

        {progLoading && !ReportsData.isLoading && (
          <div
            className="loaderProgress"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
            }}
          ></div>
        )}
      </Modal>
      {/* <PreviousVisitsReportsModal
        isLeader={props?.isLeader}
        Status={props.Status}
        id={props.id}
        modal={previousModalOpen}
        close={() => {
          setPreviousModalOpen(false);
        }}
      /> */}
    </>
  );
};

export default ReportsModal;
