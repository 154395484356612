import React from "react";
import Content from "./Content";
import Footer from "./Footer";
import Header from "./Header";

const Layout = () => {
  return (
    <div className="layout">
      <Header />
      <div className="content">
        <Content />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
