import { useQuery } from "react-query";
import { FetchComments } from "services/comments";
import ApiResult from "utils/api/models/api-result";
import { ApiError } from "utils/api/models/api-error";
import { CommentsModel } from "models/comments";

export default function useComments(id: number, show: boolean) {
  const queryKey = ["comments", { id, show }];
  return {
    ...useQuery<ApiResult<Array<CommentsModel>>, ApiError>(
      queryKey,
      () => FetchComments(id, show),
      {}
    ),
  };
}
