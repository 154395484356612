import { Button, Col, Modal, Popover, Row, Tooltip } from "antd";
import { ReportModel } from "models/reports";
import PermissionsGate, { hasPermission } from "../../../permissionsGate";
import {
  CarOutlined,
  ClockCircleOutlined,
  DeleteColumnOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { useWidth } from "utils/helpers/use-is-mobile";
import format from "utils/helpers/date-format";
import dayjs from "dayjs";
import Color from "utils/helpers/export-sass-colors";
import DateCounter from "components/DateCounter";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { UserModel } from "models/users";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import { Roles, Ticket } from "utils/helpers/static_data";
import { CiLogout } from "react-icons/ci";
import DeleteReport from "hooks/query/reports/deleteReport";
import { TicketStatus } from "models/tickets";
import i18next, { t } from "i18next";
import { MdOutlineEdit } from "react-icons/md";
import EditTransportationModal from "components/Modals/editTransportationModal";
const { confirm } = Modal;

const CardReports = ({
  Reports,
  openModel,
  openTransportationModal,
  Status,
  isUserDeniedGeo,
  isFetching,
  dontShowEditTransportation = false,
}: {
  Reports?: ReportModel[];
  openModel: (e: any) => void;
  openTransportationModal: any;
  Status: any;
  isUserDeniedGeo: boolean;
  isFetching: boolean;
  dontShowEditTransportation?: boolean;
}) => {
  const [User, setUser] = useState<UserModel | null>(null);
  const [popoverIsVisible, setPopOverIsVisible] = useState(false);
  const [id, setId] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [time, setTime] = useState({ time_in: "", time_out: "" });

  useEffect(() => {
    setUser(UserData.Decrypt());
  }, [UserData.Decrypt]);

  const Delete = DeleteReport();

  const { isMobile } = useWidth();

  useEffect(() => {
    const timer = setTimeout(() => {
      setPopOverIsVisible(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [popoverIsVisible]);

  return (
    <div>
      {Reports?.map((Report) => (
        <div
          style={{
            padding: "30px 20px 25px",
            marginBottom: 20,
            position: "relative",
            overflow: "hidden",
          }}
          className="greyBox"
          key={Report.id}
        >
          <div className="reportId">No.{Report.id}</div>
          {hasPermission(["delete_ticket_reports"]) &&
            Status === Ticket.active && (
              <Tooltip title={t("delete_report")} color="white">
                <Button
                  onClick={() => {
                    confirm({
                      title: t("sure_delete_report"),
                      icon: <ExclamationCircleOutlined />,
                      okButtonProps: {
                        children: t("delete"),
                      },
                      cancelButtonProps: {
                        children: t("cancel"),
                      },
                      okText: t("delete"),
                      cancelText: t("cancel"),
                      onOk() {
                        Delete.mutateAsync(Report.id);
                      },
                      onCancel() {},
                    });
                  }}
                  type="text"
                  danger
                  loading={Delete.isLoading}
                  icon={<DeleteOutlined />}
                  style={{
                    position: "absolute",
                    zIndex: "50",
                    left: i18next.language === "ar" ? "10px" : "",
                    right: i18next.language === "en" ? "10px" : "",
                    top: "40px",
                  }}
                ></Button>
              </Tooltip>
            )}
          <Row>
            <Col span={24} lg={24}>
              <Row>
                <Col span={24} lg={18}>
                  <div className="reportUserInfo">
                    <Link
                      to={`/technicians/${Report.checkin_user.id}`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className={`username ${
                        User?.role_id !== Roles.technical &&
                        User?.role_id !== Roles.client
                          ? ""
                          : "disabled"
                      }`}
                    >
                      <img src={Report?.checkin_user?.avatar} alt="" />
                      <div>
                        <div>
                          {Report?.checkin_user?.full_name !== undefined &&
                            Report?.checkin_user?.full_name !== null &&
                            Report?.checkin_user?.full_name}
                        </div>
                        <span className="data">
                          {Report?.checkin_user?.job_title?.name}
                        </span>
                      </div>
                    </Link>
                  </div>
                </Col>
                <Col
                  style={{ display: "flex", alignItems: "center" }}
                  span={24}
                  lg={6}
                >
                  {!dayjs(Report.time_out).isValid() && Report.time_in && (
                    <span
                      dir="ltr"
                      style={
                        dayjs(Report.time_out).isValid()
                          ? {}
                          : {
                              margin: "0",
                              color: Color.white,
                              cursor: "default",
                            }
                      }
                      className={
                        dayjs(Report.time_out).isValid() ? "" : "seeTicket wave"
                      }
                    >
                      {t("under_work")}
                    </span>
                  )}
                </Col>
                <Col span={24} lg={24}>
                  {Report.transportation_reports &&
                    Report.transportation_reports.length > 0 && (
                      <div
                        style={{
                          backgroundColor: "white",
                          borderRadius: "0.5rem",
                          padding: "1rem",
                          margin: "10px 0",
                        }}
                      >
                        <h3
                          style={{
                            textAlign: "center",
                            borderBottom: "1px solid #ccc",
                            color: Color.blue,
                            display: "flex",
                            gap: "10px",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingBottom: "10px",
                          }}
                        >
                          <div>
                            <CarOutlined style={{ marginLeft: "6px" }} />
                            {t("transporation")}
                          </div>
                        </h3>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: isMobile ? "column" : "row",
                            gap: !isMobile ? "4rem" : "1rem",
                            justifyContent: "center",
                          }}
                        >
                          {Report.transportation_reports &&
                            Report.transportation_reports.map((report) => (
                              <div key={report.id}>
                                {hasPermission(["edit_ticket_reports"]) &&
                                  !dontShowEditTransportation && (
                                    <Button
                                      size="small"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                      onClick={() => {
                                        setId(report?.id);
                                        setTime({
                                          time_in: report?.time_in,
                                          time_out: report?.time_out,
                                        });
                                        setModalOpen(true);
                                      }}
                                    >
                                      <MdOutlineEdit />
                                    </Button>
                                  )}
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "6px",
                                    justifyContent: "center",
                                  }}
                                >
                                  <h4
                                    style={{
                                      marginBottom: "2px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {report.transportation_status === "go"
                                      ? t("go")
                                      : t("away")}
                                  </h4>
                                  {report.transportation_type && (
                                    <p>
                                      {" - "}
                                      {report.transportation_type.name}
                                    </p>
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: !isMobile ? "row" : "column",
                                    gap: !isMobile ? "4rem" : "1rem",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    style={{ margin: "auto" }}
                                    className="labelReport"
                                  >
                                    {t("start")}
                                    <span dir="ltr" className="data">
                                      {dayjs(report?.time_in).format(
                                        format.date_time
                                      )}
                                    </span>
                                  </div>
                                  <div
                                    style={{ margin: "auto" }}
                                    className="labelReport"
                                  >
                                    {t("finish")}
                                    {report?.time_out ? (
                                      <span dir="ltr" className="data">
                                        {dayjs(report?.time_out).format(
                                          format.date_time
                                        )}
                                      </span>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "8px",
                                        }}
                                      >
                                        <ClockCircleOutlined spin={true} />
                                        <DateCounter
                                          date={dayjs(report?.time_in)}
                                        />
                                        <PermissionsGate
                                          scopes={[
                                            "read_ticket_reports",
                                            "add_ticket_reports",
                                          ]}
                                        >
                                          {User?.id ===
                                            Report.checkin_user.id && (
                                            <Popover
                                              visible={popoverIsVisible}
                                              trigger="click"
                                              title="لا يمكنك الانهاء"
                                              content={
                                                <div
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {t(
                                                    "please_accept_location_pirmission"
                                                  )}
                                                </div>
                                              }
                                            >
                                              <Button
                                                type="primary"
                                                onClick={
                                                  !isUserDeniedGeo
                                                    ? () =>
                                                        openTransportationModal(
                                                          Report.id
                                                        )
                                                    : () =>
                                                        setPopOverIsVisible(
                                                          (prev) => !prev
                                                        )
                                                }
                                                disabled={isFetching}
                                              >
                                                <span
                                                  style={{
                                                    color: "white",
                                                    display: "flex",
                                                    gap: "5px",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <CiLogout />
                                                  {Report.time_out === null
                                                    ? t(
                                                        "finish_tran_and_start_work"
                                                      )
                                                    : t("finish")}
                                                </span>
                                              </Button>
                                            </Popover>
                                          )}
                                        </PermissionsGate>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div style={{ display: "flex", gap: "1rem" }}>
                                  {report.time && (
                                    <div>
                                      <span>{t("time")}: </span>
                                      <span dir="ltr">{report.time}</span>
                                    </div>
                                  )}
                                  {report.cost && (
                                    <div>
                                      <span>{t("cost")}: </span>
                                      <span dir="ltr">{report.cost} TRP</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                </Col>
                <Col span={24} lg={24}>
                  {Report.time_in && (
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "0.5rem",
                        padding: "1rem",
                      }}
                    >
                      <h3
                        style={{
                          textAlign: "center",
                          borderBottom: "1px solid #ccc",
                          color: Color.blue,
                        }}
                      >
                        <FileDoneOutlined style={{ marginLeft: "6px" }} />
                        {t("work")}
                      </h3>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: isMobile ? "column" : "row",
                          justifyContent: "center",
                          gap: !isMobile ? "4rem" : "1rem",
                        }}
                      >
                        <div style={{ margin: "auto" }} className="labelReport">
                          {t("start")}
                          <span dir="ltr" className="data">
                            {dayjs(Report?.time_in).format(format.date_time)}
                          </span>
                        </div>
                        {
                          <div
                            style={{ margin: "auto" }}
                            className="labelReport"
                          >
                            {t("finish")}
                            {Report?.time_out ? (
                              <span dir="ltr" className="data">
                                {dayjs(Report?.time_out).format(
                                  format.date_time
                                )}
                              </span>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                <ClockCircleOutlined spin={true} />
                                <DateCounter date={dayjs(Report?.time_in)} />
                                <PermissionsGate
                                  scopes={[
                                    "read_ticket_reports",
                                    "add_ticket_reports",
                                  ]}
                                >
                                  {User?.id === Report.checkin_user.id && (
                                    <Button
                                      type="primary"
                                      onClick={() => openModel(Report.id)}
                                      disabled={isFetching}
                                    >
                                      <span
                                        style={{
                                          color: "white",
                                          display: "flex",
                                          gap: "5px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <CiLogout />
                                        {t("finish")}
                                      </span>
                                    </Button>
                                  )}
                                </PermissionsGate>
                              </div>
                            )}
                          </div>
                        }
                      </div>
                      {Report.time && (
                        <div>
                          <span>{t("time")}: </span>
                          <span dir="ltr">{Report.time}</span>
                        </div>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
              }}
              span={24}
              lg={24}
            >
              {Report?.time_out === null &&
              Report?.owner &&
              Report.time_in !== null &&
              hasPermission(["read_ticket_reports", "add_ticket_reports"]) ? (
                <></>
              ) : Report.time_in !== null ? (
                <PermissionsGate scopes={["read_ticket_reports"]}>
                  <span
                    onClick={() => openModel(Report.id)}
                    className={Report?.time_out ? "" : " wave"}
                  >
                    <Button type="primary">{t("details")}</Button>
                  </span>
                </PermissionsGate>
              ) : null}
            </Col>
          </Row>
        </div>
      ))}
      {id != 0 && hasPermission(["edit_ticket_reports"]) && (
        <EditTransportationModal
          id={id}
          open={modalOpen}
          time_in={time?.time_in}
          time_out={time?.time_out}
          closeModal={() => {
            setModalOpen(false);
            setId(0);
            setTime({ time_in: "", time_out: "" });
          }}
        />
      )}
    </div>
  );
};

export default CardReports;
