import { useEffect, useState } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(duration);
dayjs.extend(relativeTime);

const DateCounter = (props: any) => {
  const [counter, setCounter] = useState("");

  const startTime = dayjs(props.date);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = dayjs();
      const elapsedTime = currentTime.diff(startTime);

      const duration = dayjs.duration(elapsedTime);
      const days = duration.asDays();
      let formattedTime: any;

      if (days >= 1) {
        const hours = Math.floor(days * 24);
        formattedTime = `${hours}:${duration.format("mm:ss")}`;
      } else {
        formattedTime = duration.format("H:mm:ss");
      }

      setCounter(formattedTime);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return <span>{counter}</span>;
};

export default DateCounter;
