import { notification } from "antd";
import { hasPermission } from "components/permissionsGate/index";
import { AxiosRequestConfig } from "axios";
import ApiService from "utils/api/api-service";
import ApiResponse, { isError } from "utils/api/models/api-response";
import { ApiErrorNotification } from "utils/ui/notificationService";
import { TicketModel } from "models/tickets";

class TicketsService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: process.env.REACT_APP_API_URL,
      ...config,
    });
  }

  public async Fetch(
    page: number,
    StatusFliter: any,
    StatusPriority: any,
    DateRange: any,
    searchBy: any,
    search: any,
    isActive: any,
    sort: any,
    companyPlace: any,
    myTickets: number,
    requestedBy?: any
  ): Promise<ApiResponse<TicketModel[]>> {
    return this.get(
      `/api/tickets?page=${page}&items_per_page=8${
        StatusFliter !== "0" ? "&filter[status_id]=" + StatusFliter : ""
      }${StatusPriority !== "-1" ? "&filter[priority]=" + StatusPriority : ""}${
        DateRange !== "" && DateRange !== null
          ? "&filter[created_at]=" + DateRange
          : ""
      }${search !== "" ? "&filter[" + searchBy + "]=" + search : ""}${
        isActive === 1 ? "&filter[active]=" + isActive : ""
      }${
        myTickets !== 0 ? "&filter[my_tickets]=" + myTickets : ""
      }&index_type=list${sort !== "" ? "&sort=" + sort : ""}${
        companyPlace !== "0" ? "&filter[company_place_id]=" + companyPlace : ""
      }${requestedBy ? "&filter[requested_by_id]=" + requestedBy : ""}`
    );
  }
  public async FetchUsersTickts(
    id: any,
    perPage: any,
    page: any,
    StatusFliter: any,
    isActive: any,
    date: any
  ): Promise<ApiResponse<TicketModel[]>> {
    return this.get(
      `/api/users/${id}/tickets?items_per_page=${perPage}&page=${page}${
        StatusFliter !== "0" ? "&filter[status_id]=" + StatusFliter : ""
      }${isActive === 1 ? "&filter[active]=" + isActive : ""}${
        date !== "" && date !== null ? "&filter[report_created]=" + date : ""
      }&index_type=selector`
    );
  }
  public async FetchSingle(id: any): Promise<ApiResponse<TicketModel>> {
    return this.get(`/api/tickets/${id}?show_type=true`);
  }
  public async Post(data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/tickets/client-request`, data);
  }
  public async Update(data: any, id: any): Promise<ApiResponse<any>> {
    return this.post(`/api/tickets/${id}`, data);
  }
  public async Delete(id: any): Promise<ApiResponse<any>> {
    return this.delete(`/api/tickets/${id}`);
  }
  public async ChangeStatus(
    id: any,
    status: any,
    data: any
  ): Promise<ApiResponse<any>> {
    return this.post(`/api/tickets/${id}/statuses/${status}`, data);
  }
  public async SelfAssign(
    id: any,
    status: any,
    data: any
  ): Promise<ApiResponse<any>> {
    return this.post(
      `/api/tickets/${id}/changeStatusToAssignTechnical/${status}`,
      data
    );
  }
  public async CheckTech(id: any): Promise<ApiResponse<any>> {
    return this.get(`/api/tickets/${id}/check-tech-commitment`);
  }
  public async ReportsFiles(
    id: any,
    perPage: any,
    page: any
  ): Promise<ApiResponse<any>> {
    return this.get(
      `/api/tickets/${id}/files-reports?items_per_page=${perPage}&page=${page}`
    );
  }
  // add un rated ticket
  public async unratedTicket(userId: any): Promise<ApiResponse<any>> {
    return this.get(`/api/un-rated-ticket/${userId}`);
  }
  public async transferTicket(
    id: number,
    data: any
  ): Promise<ApiResponse<any>> {
    return this.post(`/api/tickets/${id}/change-request`, data);
  }
}

const ticketsService = new TicketsService();
// Unrated ticket controller
export const UnratedTickets = async ({ userId }: { userId: number }) => {
  if (userId) {
    const result: any = await ticketsService.unratedTicket(userId);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};

export const FetchTickets = async (
  page: number,
  StatusFliter: any,
  StatusPriority: any,
  DateRange: any,
  searchBy: any,
  search: any,
  isActive: any,
  sort: any,
  companyPlace: any,
  myTickets: number,
  requestedBy?: number
) => {
  if (
    hasPermission(["browse_tickets"]) ||
    hasPermission(["browse_ticket_pending"]) ||
    hasPermission(["browse_ticket_active"]) ||
    hasPermission(["browse_ticket_done"]) ||
    hasPermission(["browse_ticket_approved"]) ||
    hasPermission(["browse_ticket_denied"])
  ) {
    const result: any = await ticketsService.Fetch(
      page,
      StatusFliter,
      StatusPriority,
      DateRange,
      searchBy,
      search,
      isActive,
      sort,
      companyPlace,
      myTickets,
      requestedBy
    );
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const FetchUsersTickts = async (
  id: number,
  perPage: any,
  page: any,
  StatusFliter: any,
  isActive: any,
  date: any
) => {
  if (hasPermission(["browse_tickets", "read_technical_profiles"])) {
    const result: any = await ticketsService.FetchUsersTickts(
      id,
      perPage,
      page,
      StatusFliter,
      isActive,
      date
    );
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};

export const FetchTicket = async (id: any) => {
  if (hasPermission(["read_tickets"])) {
    const result: any = await ticketsService.FetchSingle(id);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const PostTicket = async (data: any) => {
  const result: any = await ticketsService.Post(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const UpdateTicketF = async ({ id, data }: any) => {
  const result: any = await ticketsService.Update(data, id);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const DeleteTicketF = async (id: any) => {
  const result: any = await ticketsService.Delete(id);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const ChangeStatusTicket = async ({ id, status, data }: any) => {
  const result: any = await ticketsService.ChangeStatus(id, status, data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const SelfAssignTicket = async ({ id, status, data }: any) => {
  if (hasPermission(["ticket_self_assign"])) {
    const result: any = await ticketsService.SelfAssign(id, status, data);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const CheckTechAsync = async (id: any) => {
  const result: any = await ticketsService.CheckTech(id);
  if (isError(result)) {
    // ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const FetchReportsFiles = async (id: any, perPage: any, page: any) => {
  if (id !== "0") {
    const result: any = await ticketsService.ReportsFiles(id, perPage, page);
    if (isError(result)) {
      // ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};

export const transferTicket = async (id: number, data: any) => {
  const result: any = await ticketsService.transferTicket(id, data);
  if (isError(result)) {
    throw result;
  } else {
    return result;
  }
};
