import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { notification } from "antd";
import { isError } from "utils/api/models/api-response";
import { RejectPlan } from "services/plan";
import { t } from "i18next";

export default function useRejectPlan() {
  const queryClient = useQueryClient();
  return {
    ...useMutation(RejectPlan, {
      onSuccess: async (res) => {
        if (!isError(res)) {
          notification.open({
            type: "success",
            message: t("the_plan_has_been_rejected_successfully"),
          });
          queryClient.invalidateQueries("plans");
        }
      },
    }),
  };
}
