import { AxiosRequestConfig } from "axios";
import ApiService from "utils/api/api-service";
import ApiResponse, { isError } from "utils/api/models/api-response";
import { ApiErrorNotification } from "utils/ui/notificationService";

class SchedulesService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: process.env.REACT_APP_API_URL,
      ...config,
    });
  }
  public async Post(id: any, data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/tickets/${id}/schedules`, data);
  }
  public async reject(id: any, data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/schedules/${id}/statuses/rejected`, data);
  }
}

const schedulesService = new SchedulesService();

export const postSchedules = async (obj: any) => {
  const result: any = await schedulesService.Post(obj.id, obj.data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const rejectSchedules = async ({ id, data }: any) => {
  const result: any = await schedulesService.reject(id, data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
