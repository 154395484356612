import { PrinterFilled } from "@ant-design/icons";
import { Button, Checkbox, Col, Input, Row, Table, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import useCategories, {
  useCategoriesList,
} from "hooks/query/categories/useCategories";
import { Roles } from "utils/helpers/static_data";
import dayjs from "dayjs";
import format from "utils/helpers/date-format";
import { UserModel } from "models/users";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import HeaderLogo from "assets/images/logo1.png";
import { isClient } from "utils/helpers/check-roles";
import i18next, { t } from "i18next";
import { hasPermission } from "components/permissionsGate";

const columns = [
  {
    title: "",
    dataIndex: "",
    render: (text: any, record: any, index: any) => index + 1,
  },
  {
    title: t("product_name"),
    dataIndex: "name",
  },
  {
    title: t("qty"),
    dataIndex: "quantity",
  },
];

/**
 * the final report of Ticket
 */
const FormalReportPage = ({ data, onChange, reportRef }: any) => {
  const CategoriesData = useCategoriesList();
  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserData.Decrypt]);

  const client = User?.role_id === Roles.client;
  const accountant = User?.role_id === Roles.accountant;
  const sales = User?.role_id === Roles.sales;

  const columns2 = [
    {
      title: "",
      dataIndex: "",
      render: (text: any, record: any, index: any) =>
        data?.additional_products.slice(
          0,
          Math.ceil(data?.additional_products.length / 2)
        ).length +
        index +
        1,
    },
    {
      title: t("product_name"),
      dataIndex: "name",
    },
    {
      title: t("qty"),
      dataIndex: "quantity",
    },
  ];

  const componentRef: any = useRef<any>(null);

  return (
    <>
      <div className="formalReport-btn">
        <ReactToPrint
          trigger={() => (
            <Button type="primary" className="print-btn">
              {t("print")}
              <PrinterFilled />
            </Button>
          )}
          content={() => componentRef.current}
        />
      </div>
      <div
        ref={componentRef}
        className={
          !client && !accountant && !sales
            ? "formalReportPage"
            : "formalReportPage print"
        }
      >
        <div className="reportHeader">
          <div>
            {/* <div className="headerTitle">تقرير دعم الفني</div> */}
            <div className="reportHistory">
              {t("at_date")} {data?.date.replaceAll("-", "/")}
            </div>
          </div>
          <div>
            <img className="headerLogo" src={HeaderLogo} alt="" />
          </div>
        </div>
        <div className="infoNav">
          <div className="reportId">
            {isClient(User?.role_id!) ? data?.public_id : data?.id}
          </div>
        </div>
        <div className="reportInfos">
          <div className="reportInfo">
            <div className="label">{t("client_name")} :</div>
            <span className="value">{data?.client_name}</span>
          </div>
          <div className="reportInfo">
            <div className="label">{t("address")} :</div>
            <span className="value">{data?.address}</span>
          </div>

          <div className="reportInfo">
            <div className="label">{t("service_level")} :</div>
            <span className="value checkBoxes">
              {CategoriesData.data?.data.map((category: any) => (
                <span key={category.id} className="formCheck">
                  <Checkbox checked={category.id === data?.category?.id}>
                    {category.name}
                  </Checkbox>
                </span>
              ))}
            </span>
          </div>
          <div className="reportInfo">
            <div className="label">{t("sub_category_name")} :</div>
            <span className="value checkBoxes">
              {" "}
              <span className="formCheck">
                <Checkbox checked={true}>
                  {data?.service?.name
                    ? data?.service?.name
                    : data?.service?.name_en}
                </Checkbox>
              </span>
            </span>
          </div>
          <div style={{ display: "block" }} className="reportInfo">
            <div className="label">{t("description")} :</div>
            <div className="value">{data?.description}</div>
          </div>
          <div style={{ display: "block" }} className="reportInfo">
            <div className="label">{t("technical_report")} :</div>
            <div className="value textarea">
              <Input.TextArea
                defaultValue={
                  data?.formal_report &&
                  data?.formal_report
                    .toString()
                    .replaceAll(String.raw`\n`, "\n")
                }
                minLength={50}
                maxLength={10000}
                dir="auto"
                autoComplete="off"
                autoSize
                onChange={(e) => onChange(e.target.value)}
              />
            </div>
            <div className="value vprint">
              {data?.formal_report &&
                data?.formal_report.toString().replaceAll(String.raw`\n`, "  ")}
            </div>
          </div>
          {data?.additional_products?.length > 0 && (
            <Row>
              <Col span={24}>
                <div className="tableHeader">{t("additionals_products")}</div>
              </Col>
              <Col span={data?.additional_products.length > 1 ? 12 : 24}>
                <Table
                  className="products"
                  pagination={false}
                  columns={columns}
                  dataSource={data?.additional_products.slice(
                    0,
                    Math.ceil(data?.additional_products.length / 2)
                  )}
                  bordered
                />
              </Col>
              {data?.additional_products.length > 1 && (
                <Col span={12}>
                  <Table
                    className="products"
                    pagination={false}
                    columns={columns2}
                    dataSource={data?.additional_products.slice(
                      -Math.floor(data?.additional_products.length / 2)
                    )}
                    bordered
                  />
                </Col>
              )}
            </Row>
          )}

          {(data?.status?.key === "ticket_denied" ||
            data?.status?.key === "ticket_approved") &&
            hasPermission(["browse_rates"]) && (
              <>
                {data?.response_rate !== -1 && (
                  <>
                    <div className="reportInfo">
                      <div className="label">{t("quality_of_service")} :</div>
                      <span className="value checkBoxes">
                        <span className="formCheck">
                          <Checkbox checked={data?.quality_rate === 1}>
                            {t("bad")}
                          </Checkbox>
                        </span>
                        <span className="formCheck">
                          <Checkbox checked={data?.quality_rate === 2}>
                            {t("medium")}
                          </Checkbox>
                        </span>
                        <span className="formCheck">
                          <Checkbox checked={data?.quality_rate === 3}>
                            {t("good")}
                          </Checkbox>
                        </span>
                        <span className="formCheck">
                          <Checkbox checked={data?.quality_rate === 4}>
                            {" "}
                            {t("very_good")}
                          </Checkbox>
                        </span>
                        <span className="formCheck">
                          <Checkbox checked={data?.quality_rate === 5}>
                            {t("excellent")}
                          </Checkbox>
                        </span>
                      </span>
                    </div>
                    <div className="reportInfo">
                      <div className="label">{t("responsiveness")} :</div>
                      <span className="value checkBoxes">
                        <span className="formCheck">
                          <Checkbox checked={data?.response_rate === 1}>
                            {t("bad")}
                          </Checkbox>
                        </span>
                        <span className="formCheck">
                          <Checkbox checked={data?.response_rate === 2}>
                            {t("medium")}
                          </Checkbox>
                        </span>
                        <span className="formCheck">
                          <Checkbox checked={data?.response_rate === 3}>
                            {t("good")}
                          </Checkbox>
                        </span>
                        <span className="formCheck">
                          <Checkbox checked={data?.response_rate === 4}>
                            {" "}
                            {t("very_good")}
                          </Checkbox>
                        </span>
                        <span className="formCheck">
                          <Checkbox checked={data?.response_rate === 5}>
                            {t("excellent")}
                          </Checkbox>
                        </span>
                      </span>
                    </div>
                  </>
                )}
                <div style={{ display: "block" }} className="reportInfo">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: 0,
                      marginBottom: 10,
                    }}
                    className="label"
                  >
                    {t("customer_notes")} :{" "}
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="label">
                          {i18next.language === "ar"
                            ? "تم انهاء العمل؟"
                            : "Work done?"}{" "}
                          :
                        </div>
                        <span className="">
                          {" "}
                          <span className="formCheck">
                            <Checkbox
                              checked={data?.status?.key === "ticket_approved"}
                            >
                              {t("yes")}
                            </Checkbox>
                          </span>
                          <span className="formCheck">
                            <Checkbox
                              checked={data?.status?.key === "ticket_denied"}
                            >
                              {t("no")}
                            </Checkbox>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="value">{data?.rate_content}</div>
                </div>
              </>
            )}
          {data?.leader !== null && (
            <div className="reportInfo">
              <div className="label">{t("ticket_leader")} :</div>
              <span className="value">{data?.leader}</span>
            </div>
          )}
          {data?.technicians && data?.technicians !== null && (
            <div className="reportInfo">
              <div className="label">{t("technicals")} :</div>
              <span className="value">{data?.technicians}</span>
            </div>
          )}
          <Row>
            <Col span={8}>
              <div style={{ display: "block" }} className="reportInfo">
                <div className="label">{t("entry_time")} :</div>
                <div className="value">
                  {dayjs(data?.time.in).format(format.date_time)}
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div style={{ display: "block" }} className="reportInfo">
                <div className="label">{t("finish_time")} :</div>
                <div className="value">
                  {dayjs(data?.time.out).format(format.date_time)}
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div style={{ display: "block" }} className="reportInfo">
                <div className="label">
                  <span className="blue">*</span> {t("total_time")} :
                </div>
                <div className="value">{data?.time.total_working_time}</div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="reportFooter">
          <Row>
            <Col span={6}>
              <div className="footerInfo">Damascus - Syria</div>
              <div className="footerInfo">Support@Wareed.com</div>
            </Col>
            <Col span={6}>
              <div className="footerInfo">Hot Line: +90(11) 4003</div>
              <div className="footerInfo">www.Wareed.com</div>
            </Col>
            <Col span={6}>
              <div className="footerInfo">Mobile: +90 956 743 743</div>
            </Col>
            <Col span={6}>
              <div className="footerInfo note">
                {t("note")}:{" "}
                {i18next.language === "ar"
                  ? "اجزاء الساعة تحسب ساعة"
                  : "Parts of hours equal to hour"}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default FormalReportPage;
