import useFinalReports from "hooks/query/reports/useFinalReports";
import { useState } from "react";
import { ColumnsType } from "antd/lib/table/interface";
import { FinalReport } from "models/reports";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";
import PageTitle from "components/UI/PageTitle";
import { SyncOutlined } from "@ant-design/icons";
import ClientRequestsFillters, {
  filters,
} from "components/filters/clientRequestsFillters";
import CustomTable from "components/table";
import { TicketServiceModel } from "models/ticketServices";
import { UserModel } from "models/users";
import { truncateText } from "utils/helpers/truncate-text";
import FormalReportModal from "components/Modals/formalReportModal";

type Props = {};

let CurrentPage = 1;
let CurrentFilters: filters & {
  DateRange: string;
  SearchText: string;
  SearchBy: string;
} = {
  Status: undefined,
  Sort: "",
  Priority: "-1",
  isActive: 0,
  myClientRequests: 0,
  DateRange: "",
  SearchText: "",
  SearchBy: "formal_report",
};

function FinalReports({}: Props) {
  const { t } = useTranslation();
  const perPage = 8;
  const [Page, setPage] = useState(1);
  const [SearchText, setSearch] = useState<string>("");
  const [SearchBy, setSearchBy] = useState<string>("formal_report"); //default search by
  const [IsModalFormal, setIsModalFormal] = useState(false);
  const [id, setId] = useState(0);

  const changePage = (e: any) => {
    setPage(e);
    CurrentPage = e;
  };

  const { data, isLoading, isFetching, refetch } = useFinalReports(
    Page,
    perPage,
    SearchBy,
    SearchText
  );

  const columns: ColumnsType<FinalReport> = [
    {
      title: t("id"),
      dataIndex: "id",
      width: "10%",
      render: (id: number) => (
        <div
          onClick={() => {
            setId(id);
            setIsModalFormal(true);
          }}
          className="pointer"
          style={{ color: "#1677ff" }}
        >
          {id}
        </div>
      ),
    },
    {
      title: t("service"),
      dataIndex: "service",
      width: "15%",
      render: (service: TicketServiceModel) => <div>{service.name}</div>,
    },
    {
      title: (
        <span style={{ display: "inline-block", marginLeft: "6px" }}>
          {t("customer")}
        </span>
      ),
      dataIndex: "user",
      width: "15%",
      render: (user: UserModel) => {
        return <div>{user.full_name}</div>;
      },
    },
    {
      title: t("description"),
      dataIndex: "description",
      width: "25%",
      render: (description: string) => (
        <div>{truncateText(description, 40)}</div>
      ),
    },
    {
      title: t("final_report"),
      dataIndex: "final_report",
      width: "25%",
      render: (final_report: string) => (
        <span>{truncateText(final_report, 40)}</span>
      ),
    },
  ];

  const deviceColumns: ColumnsType<FinalReport> = [
    {
      title: "",
      render: (obj: FinalReport) => {
        return (
          <div
            style={{
              border: `3px solid #eee`,
              padding: "10px 5px",
            }}
            className="mobileCard"
            onClick={() => {
              setId(obj.id);
              setIsModalFormal(true);
            }}
          >
            <Row style={{ padding: "20px", paddingTop: "0" }}>
              <Col md={12} span={24}>
                <div className="label">
                  <span> {t("id")} :</span>
                  {obj.id}
                </div>
              </Col>
              <Col md={12} span={24}>
                <div className="label">
                  <span>{t("service")}</span>
                  <span
                    style={{
                      width: "fit-content",
                      padding: "7px",
                      borderRadius: "50%",
                    }}
                  >
                    {obj.service.name}
                  </span>
                </div>
              </Col>
              <Col md={12} span={24}>
                <div className="label">
                  <span> {t("customer")} :</span>
                  <span dir="ltr">{obj.user.full_name}</span>
                </div>
              </Col>
              <Col md={12} span={24}>
                <div className="label">
                  <span> {t("description")} :</span>
                  {truncateText(obj.description, 30)}
                </div>
              </Col>
              <Col md={12} span={24}>
                <div className="label">
                  <span> {t("final_report")} :</span>
                  <span>{truncateText(obj.final_report, 30)}</span>
                </div>
              </Col>
            </Row>
          </div>
        );
      },
    },
  ];
  return (
    <div className="tickets">
      <PageTitle isAbsolute={true}>{t("final_reports")}</PageTitle>
      <div
        style={{
          justifyContent: "end",
        }}
        className="altTicketBtns"
      >
        <div className="actionBtns">
          <Button
            onClick={() => refetch?.()}
            type="primary"
            className="loadingBtn"
          >
            <SyncOutlined spin={isFetching} />
          </Button>
        </div>
      </div>

      <ClientRequestsFillters
        SearchText={SearchText}
        SearchBy={SearchBy}
        setSearch={(search: string) => {
          CurrentPage = 1;
          setSearch(search);
          CurrentFilters.SearchText = search;
        }}
        setSearchBy={(searchBy: string) => {
          CurrentPage = 1;
          setSearchBy(searchBy);
          CurrentFilters.SearchBy = searchBy;
        }}
        multiSearch="finalReport"
        isFetching={isFetching}
      />
      <CustomTable
        isLoading={isLoading}
        columns={columns}
        mobileColumns={deviceColumns}
        dataSource={data?.data}
        page={(e) => changePage(e)}
        current={Page}
        totalPage={data && data.meta && (data?.meta?.total as any)}
      />
      {IsModalFormal && (
        <FormalReportModal
          modal={IsModalFormal}
          id={id}
          Status={""}
          close={() => setIsModalFormal(false)}
          dontShowOld={true}
        />
      )}
    </div>
  );
}

export default FinalReports;
