import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { isError } from "utils/api/models/api-response";
import { AddCheckinReport } from "services/reports";

export default function CheckinReport() {
  const queryClient = useQueryClient();
  return {
    ...useMutation(AddCheckinReport, {
      onSuccess: async (res) => {
        if (!isError(res)) {
          queryClient.invalidateQueries("ticket");
          queryClient.invalidateQueries("Reports");
          queryClient.invalidateQueries("myPlan");
        }
      },
    }),
  };
}
