import { Button, Col, Form, Input, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import "jodit/build/jodit.min.css";
import { t } from "i18next";
import dayjs from "dayjs";
import format from "utils/helpers/date-format";
import useUpdateTransportation from "hooks/query/transportation/useUpdateTransportation";

interface Props {
  time_in: string;
  time_out: string;
  id: number;
  closeModal: () => void;
  open: boolean;
}

const EditTransportationModal = ({
  time_in,
  time_out,
  id,
  closeModal,
  open,
}: Props) => {
  const [transportation] = Form.useForm();
  const [isTimeInChanged, setIsTimeInChanged] = useState(false);
  const [isTimeOutChanged, setIsTimeOutChanged] = useState(false);
  const changeTimeInHandler = (e: any) => {
    const changedDate = dayjs(e.target.value.split("T").join(" ")).format(
      format.date_time
    );
    const timeIn = dayjs(time_in).format(format.date_time);
    setIsTimeInChanged(changedDate !== timeIn);
  };
  const changeTimeOutHandler = (e: any) => {
    const changedDate = dayjs(e.target.value.split("T").join(" ")).format(
      format.date_time
    );
    const timeOut = dayjs(time_out).format(format.date_time);
    setIsTimeOutChanged(changedDate !== timeOut);
  };
  useEffect(() => {
    setIsTimeInChanged(false);
    setIsTimeOutChanged(false);
  }, [open]);

  const update = useUpdateTransportation();

  const UpdateTransportationSubmit = (e: any) => {
    const formData = new FormData();

    if (e.time_in) formData.append("time_in", e.time_in?.replace("T", " "));
    if (e.time_out) formData.append("time_out", e.time_out?.replace("T", " "));
    formData.append("_method", "PUT");

    update
      .mutateAsync({
        id: id,
        data: formData,
      })
      .then(() => {
        closeModal();
      });
  };

  return (
    <Modal
      key={"reportModal" + id}
      width={"50%"}
      centered
      onCancel={() => closeModal()}
      open={open}
      footer={[
        <Button
          type="primary"
          onClick={() => closeModal()}
          className="closeBtn"
          key="back"
        >
          {t("close")}
        </Button>,
        <Button
          key="add"
          className="addRwview"
          onClick={() => transportation.submit()}
          type="primary"
          loading={update.isLoading}
          disabled={
            !transportation.getFieldValue("time_in") ||
            !transportation.getFieldValue("time_out") ||
            (!isTimeInChanged && !isTimeOutChanged) 
          }
        >
          {t("edit_transportation")}
        </Button>,
      ]}
    >
      <Form
        form={transportation}
        layout="vertical"
        onFinish={UpdateTransportationSubmit}
        name={"report" + id}
      >
        <div
          style={{
            padding: "30px 20px 25px",
            marginBottom: 20,
            position: "relative",
            overflow: "hidden",
          }}
          className="greyBox"
        >
          <Row justify="center" gutter={[20, 0]}>
            <Col span={24} lg={12}>
              <Form.Item
                label={<>{t("start")}</>}
                name={"time_in"}
                initialValue={time_in?.replace(/\s/g, "T")}
                //   rules={[{ required: props.Status === Ticket.active }]}
              >
                <Input
                  type={"datetime-local"}
                  autoComplete="off"
                  onChange={changeTimeInHandler}
                />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item
                label={<>{t("finish")}</>}
                name={"time_out"}
                initialValue={time_out?.replace(/\s/g, "T")}
                // rules={[
                //   {
                //     required:
                //       props.Status === Ticket.active && props.id === 0,
                //   },
                // ]}
              >
                <Input
                  type={"datetime-local"}
                  autoComplete="off"
                  onChange={changeTimeOutHandler}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default EditTransportationModal;
