import { notification } from "antd";
import Text from "antd/lib/typography/Text";
import i18next, { t } from "i18next";

export const ApiErrorNotification = (error: any) => {
  let { message, errors } = error;

  if (message && message !== "Your account is disabled") {
    notification.config({ maxCount: 3 });
    notification.open({
      type: "error",
      message: <Text>{message}</Text>,
      description:
        errors && Object.keys(errors).map((err) => <div>{errors[err]}</div>),
    });
  } else {
    notification.open({
      type: "error",
      message: <Text>{t("unexpected_error")}</Text>,
    });
  }
};
