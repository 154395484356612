import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { notification } from "antd";
import { isError } from "utils/api/models/api-response";
import { PostComment } from "services/comments";
import i18next from "i18next";

export default function AddComment(form: any) {
  const queryClient = useQueryClient();
  return {
    ...useMutation(PostComment, {
      onSuccess: async (res) => {
        if (!isError(res)) {
          notification.open({
            message:
              i18next.language === "ar"
                ? "تمت اضافة التعليق"
                : "Comment has been added successfully",
          });
          queryClient.invalidateQueries("comments");
          form.resetFields();
        }
      },
    }),
  };
}
