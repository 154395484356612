import { useQuery } from "react-query";
import { TicketModel } from "models/tickets";
import { FetchTicket } from "services/tickets";
import { ApiError } from "utils/api/models/api-error";
import ApiResult from "utils/api/models/api-result";

export default function useTicket(id: number) {
  const queryKey = ["ticket", { id }];
  return {
    ...useQuery<ApiResult<TicketModel>, ApiError>(
      queryKey,
      () => FetchTicket(id),
      {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        staleTime: 0,
        enabled: !!id,
      }
    ),
  };
}
