import { useInfiniteQuery, useQuery } from "react-query";
import { FetchCompanyPlaces } from "services/places";
import ApiResult from "utils/api/models/api-result";
import { PlaceModel } from "models/companies";
import { ApiError } from "utils/api/models/api-error";
import useDebounce from "hooks/others/useDebounce";

export default function usePlaces(
  id: any,
  page: any,
  perPage: any,
  search: any
) {
  const queryKey = ["Places", id, page, perPage, search];
  return {
    ...useQuery<ApiResult<PlaceModel[]>, ApiError>(
      queryKey,
      () => FetchCompanyPlaces(id, page, perPage, search),
      {
        staleTime: 10000,
      }
    ),
  };
}

export function useInfinitePlaces(
  id: any,
  page: number,
  perPage: any,
  search: any
) {
  const { newParam: searchParam } = useDebounce(search);
  const queryKey = ["Places", id, perPage, searchParam];
  return {
    ...useInfiniteQuery<ApiResult<PlaceModel[]>, ApiError>(
      queryKey,
      async ({ pageParam = 1 }) =>
        await FetchCompanyPlaces(id, pageParam, perPage, search),
      {
        keepPreviousData: true,
        getNextPageParam: (lastPage) =>
          lastPage?.meta?.currentPage! + 1 ?? false,
      }
    ),
  };
}
