import { Button, Col, Popconfirm, Row } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";
import AddClassificationModal from "components/Modals/classificationsModals/AddClassificationModal";
import DeleteClassification from "hooks/query/classifications/DeleteClassifications";
import useClassifications from "hooks/query/classifications/useClassifications";
import { CategoryModel } from "models/categories";
import { useState } from "react";
import Color from "utils/helpers/export-sass-colors";
import { useWidth } from "utils/helpers/use-is-mobile";
import EditClassificationModal from "components/Modals/classificationsModals/EditClassificationModal";
import { PlusOutlined } from "@ant-design/icons";
import AddClassifications from "hooks/query/classifications/AddClassifications";
import UpdateClassification from "hooks/query/classifications/editClassifications";
import PermissionsGate from "components/permissionsGate";
import i18next, { t } from "i18next";
import usePagination from "hooks/others/usePagination";

const ClientCategories = () => {
  const { isMobile } = useWidth();
  const { data, isLoading, isFetching } = useClassifications();
  const [addOpen, setAddOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [selectedItem, setSelecetedItem] =
    useState<CategoryModel | undefined>();

  const { page, perPage, setPage } = usePagination(8);

  const editHandler = (item: CategoryModel) => {
    setSelecetedItem(item);
    setUpdateOpen(true);
  };

  const Delete = DeleteClassification();

  const add = AddClassifications();
  const update = UpdateClassification();

  const columns: ColumnsType<CategoryModel> = [
    {
      title: t("arabic_name"),
      dataIndex: "name_ar" ?? "name",
      align: "center",
      width: "20%",
    },
    {
      title: t("english_name"),
      dataIndex: "name_en",
      align: "center",
      width: "25%",
    },
    {
      title: "",
      align: "center",
      width: 200,
      render(value, record) {
        return (
          <div>
            <PermissionsGate scopes={["edit_categories"]}>
              <Button type="link" onClick={() => editHandler(record)}>
                {t("update")}
              </Button>
            </PermissionsGate>
            <PermissionsGate scopes={["delete_categories"]}>
              <Popconfirm
                okType="danger"
                title={
                  i18next.language === "ar"
                    ? "هل انت متأكد من الحذف؟"
                    : "Are you sure to delete?"
                }
                onConfirm={() => {
                  return new Promise((resolve, reject) => {
                    Delete.mutateAsync(record.id)
                      .then(() => {
                        resolve(null);
                      })
                      .catch(() => {
                        reject();
                      });
                  });
                }}
              >
                <Button danger type="link">
                  {t("delete")}
                </Button>
              </Popconfirm>
            </PermissionsGate>
          </div>
        );
      },
    },
  ];

  const deviceColumns: ColumnType<CategoryModel>[] = [
    {
      title: "",
      render: (obj: CategoryModel) => {
        return (
          <div
            style={{
              padding: "0",
              borderRadius: "18px",
              overflow: "hidden",
              border: `2px solid ${Color.blue}`,
              width: "90%",
              margin: "auto",
            }}
          >
            <Row style={{ padding: "20px" }}>
              <Col md={12} span={24}>
                <div className="label" style={{ marginBottom: "4px" }}>
                  <span>{t("arabic_name")}: </span>
                  {obj.name_ar}
                </div>
              </Col>
              <Col md={12} span={24}>
                <div className="label" style={{ marginBottom: "4px" }}>
                  <span>{t("english_name")}: </span>
                  {obj.name_en}
                </div>
              </Col>

              <Col md={12} span={24}>
                <PermissionsGate scopes={["edit_categories"]}>
                  <Button type="link" onClick={() => editHandler(obj)}>
                    {t("update")}
                  </Button>
                </PermissionsGate>
                <PermissionsGate scopes={["delete_categories"]}>
                  <Popconfirm
                    okType="danger"
                    title={
                      i18next.language === "ar"
                        ? "هل انت متأكد من الحذف؟"
                        : "Are you sure to delete?"
                    }
                    onConfirm={() => {
                      return new Promise((resolve, reject) => {
                        Delete.mutateAsync({ id: obj.id })
                          .then(() => {
                            resolve(null);
                          })
                          .catch(() => {
                            reject();
                          });
                      });
                    }}
                  >
                    <Button danger type="link">
                      {t("delete")}
                    </Button>
                  </Popconfirm>
                </PermissionsGate>
              </Col>
            </Row>
          </div>
        );
      },
    },
  ];

  const addClassificationHandler = ({ data }: any) => {
    add.mutateAsync({ data }).then(() => {
      setAddOpen(false);
    });
  };

  const updateClassificatoinHandler = ({ id, data }: any) => {
    update.mutateAsync({ id, data }).then(() => {
      setUpdateOpen(false);
    });
  };

  return (
    <div className="categories">
      <PermissionsGate scopes={["add_categories"]}>
        <div style={{ textAlign: "end", marginBottom: "1rem" }}>
          <Button
            onClick={() => setAddOpen(true)}
            type="primary"
            icon={<PlusOutlined />}
          >
            {t("add")}
          </Button>
        </div>
      </PermissionsGate>
      <PermissionsGate scopes={["add_categories"]}>
        <AddClassificationModal
          isLoading={add.isLoading}
          open={addOpen}
          setOpen={setAddOpen}
          onAdd={addClassificationHandler}
        />
      </PermissionsGate>
      <PermissionsGate scopes={["edit_categories"]}>
        <EditClassificationModal
          open={updateOpen}
          setOpen={setUpdateOpen}
          item={selectedItem}
          isLoading={update.isLoading}
          onUpdate={updateClassificatoinHandler}
        />
      </PermissionsGate>
      <div>
        <Table
          bordered
          columns={isMobile ? deviceColumns : columns}
          dataSource={data?.data}
          loading={isLoading || isFetching}
          pagination={{
            current: page,
            pageSize: perPage,
            total: data?.meta.total,
            onChange(page, pageSize) {
              setPage(page);
            },
            style: {
              justifyContent: "flex-start",
            },
          }}
        />
      </div>
    </div>
  );
};

export default ClientCategories;
