import { useEffect, useState } from "react";

interface props {
  isFetching: boolean;
}

const useProgressLoading = ({ isFetching }: props) => {
  const [progLoading, setProgLoading] = useState(false);
  useEffect(() => {
    if (isFetching) {
      setProgLoading(true);
    }
    if (!isFetching) {
      setTimeout(() => {
        setProgLoading(false);
      }, 1500);
    }
  }, [isFetching]);
  return progLoading;
};

export default useProgressLoading;
