import { useQueryClient } from "react-query";
import { DeleteTicketF } from "services/tickets";
import { useMutation } from "react-query";
import { notification } from "antd";
import { isError } from "utils/api/models/api-response";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

export default function DeleteTicket() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return {
    ...useMutation(DeleteTicketF, {
      onSuccess: async (res) => {
        if (!isError(res)) {
          notification.open({
            message: t("ticket_deleted_successfully"),
          });
          navigate("/client-requests");
        }
      },
    }),
  };
}
