/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect } from "react";
import { Button, notification } from "antd";
import { Link } from "react-router-dom";
import { LOGIN_NOT_VERIFY } from "actions/types";
import LoginForm from "./loginForm";
import Container from "../container";
import OTPForm from "../OTP";
import { useAppSelector } from "store";
import ArrowIcon from "assets/images/arrow.png";
import { useNavigate } from "react-router-dom";
import i18next, { t } from "i18next";
import BackButton from "components/UI/BackButton";
/**
 * login page to the izone platform
 */

const Login: FC = ({ match }: any) => {
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const err = useAppSelector((state) => state.auth.err);
  const [OTP, setOTP] = useState<boolean>(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (isLoggedIn) {
      navigate({
        pathname: "/client-requests",
      });
    }
  }, [isLoggedIn]);

  const key = ["key1", "key2"];

  useEffect(() => {
    if (err === LOGIN_NOT_VERIFY) {
      notification.config({
        maxCount: 2,
      });
      notification.open({
        type: "warning",
        key: key[0],
        message: "Warning",
        description: "Your phone number not verified!",
        duration: 30,
      });
      notification.open({
        message: i18next.language === "ar" ? "ارسال رمز التفعيل؟" : "Send otp?",
        key: key[1],
        btn: (
          <>
            <Button
              className="installBtn"
              style={{ margin: "0 10px" }}
              type="primary"
              onClick={() => {
                setOTP(true);
                notification.destroy(key[0]);
                notification.destroy(key[1]);
              }}
            >
              {t("send")}
            </Button>
            <Button
              onClick={() => {
                notification.destroy(key[0]);
                notification.destroy(key[1]);
              }}
            >
              {t("cancel")}
            </Button>
          </>
        ),
        duration: 30,
      });
    }
  }, [err]);

  return (
    <>
      <Container>
        {!OTP ? (
          <LoginForm />
        ) : (
          <>
            <BackButton
              title={t("login")}
              onClick={() => {
                navigate("/login");
                setOTP(false);
              }}
            />
            <OTPForm setOTP={setOTP} email={email} />
          </>
        )}
      </Container>
    </>
  );
};

export default Login;
