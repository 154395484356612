import { CaretDownOutlined, SyncOutlined } from "@ant-design/icons";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Dropdown,
  Input,
  Radio,
  Select,
  Switch,
} from "antd";
import type { MenuProps } from "antd";
import { useEffect, useState } from "react";
import { useWidth } from "utils/helpers/use-is-mobile";
import { Roles, Ticket, TicketKeyName } from "utils/helpers/static_data";
import { hasPermission } from "components/permissionsGate";
import { StatusColor } from "utils/helpers/ticket_status";
import Color from "utils/helpers/export-sass-colors";
import dayjs from "dayjs";
import isTomorrow from "dayjs/plugin/isTomorrow";
import { ItemType } from "antd/es/menu/hooks/useItems";
import format from "utils/helpers/date-format";
import { UserModel } from "models/users";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import i18next, { t } from "i18next";
import { direction } from "utils/ui/LocaleDirection";
import { AiOutlineSchedule } from "react-icons/ai";

dayjs.extend(isTomorrow);
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;

export interface filters {
  Status?: string;
  SearchText?: string;
  setStatus?(status: filters["Status"]): void;
  setDate?(date: any): void;
  setSearch?(search: string): void;
  isDone: boolean;
  setIsDone: Function;
}

const PlansFilters = ({
  Status,
  setStatus,
  setSearch,
  setDate,
  SearchText,
  setIsDone,
  isDone,
}: filters) => {
  const { isMobile } = useWidth();

  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserData.Decrypt]);

  const ChangeDate = (e: any) => {
    if (e === null) {
      setDate?.("");
    } else if (e[0] !== null && e[1] !== null && e !== null) {
      setDate?.(e[0].format("YYYY-MM-DD") + "," + e[1].format("YYYY-MM-DD"));
    } else {
      setDate?.("");
    }
  };

  const handleClick = (e: any) => {
    setFilterBy(KeyToValue(e.key));
  };
  const filterByKyes = {
    plan: {
      creator_by: t("plan_creator"),
    },
  };
  const KeyToValue = (key?: any): any => {
    let value: ItemType = {
      label: "...",
      key: "null",
      onClick: (e) => handleClick(e),
    };
    if (key !== undefined)
      return {
        label: (filterByKyes as any)["plan"][key],
        key: Object.keys((filterByKyes as any)["plan"]).filter(
          (i) => i === key
        )[0],
        onClick: (e) => handleClick(e),
      } as ItemType;
    return value;
  };

  const [filterBy, setFilterBy] = useState<any>(KeyToValue(""));
  const [filterSearchText, setFilterSearchText] = useState<string>("");

  useEffect(() => {
    setFilterSearchText(SearchText!);
    setFilterBy(KeyToValue("creator_by"));
  }, [SearchText, i18next.language]);

  const client_request: MenuProps["items"] = [KeyToValue("creator_by")];
  return (
    <div
      dir={direction[i18next.language]}
      style={{ paddingTop: "10px" }}
      className="ticketBtns"
    >
      <div
        className="ticketsFilters"
        style={{
          display: "flex",
          gap: "1rem",
          justifyContent: "flex-start",
          height: "100%",
          flexWrap: isMobile ? "wrap" : "nowrap",
          marginTop: isMobile ? 10 : 0,
          width: isMobile ? "100%" : "initial",
        }}
      >
        {Status && (
          <div
            style={{
              flex: isMobile ? "50%" : "initial",
            }}
          >
            <div className="label">{t("plan_status")}</div>
            <Select
              onChange={(e: filters["Status"]) => setStatus?.(e)}
              className={"changeStatusSelect status" + Status}
              style={isMobile ? {} : { width: "150px" }}
              value={Status}
              suffixIcon={
                <CaretDownOutlined
                  className="ant-select-suffix"
                  style={{ color: "white" }}
                />
              }
            >
              <Option value={Ticket.create}>
                <div className="dot" />
                {t("all")}
              </Option>

              <Option value={Ticket.pending}>
                <div
                  className="dot"
                  style={{ backgroundColor: StatusColor(1) }}
                />
                {TicketKeyName.pending()}
              </Option>

              <Option value={Ticket.approved}>
                <div
                  className="dot"
                  style={{ backgroundColor: StatusColor(4) }}
                />
                {TicketKeyName.approved()}
              </Option>

              <Option value={Ticket.denied}>
                <div
                  className="dot"
                  style={{ backgroundColor: StatusColor(5) }}
                />
                {TicketKeyName.denied()}
              </Option>
            </Select>
          </div>
        )}

        {/* {setSearch !== undefined && (
          <Search
            style={{
              width: "unset",
              flex: isMobile ? "50%" : "initial",
              marginTop: "21px",
              marginLeft: isMobile ? "0" : "10px",
              paddingLeft: "6px",
            }}
            placeholder={t("search")}
            onSearch={(e: any) => setSearch?.(e)}
            onChange={(e: any) => {
              if (e.target.value === "") setSearch?.("");
              else if (e.target.value.length > 2) setSearch?.(e.target.value);
              else if (e.target.value.length < 3) setSearch?.("");
            }}
          />
        )} */}

        {setSearch !== undefined && (
          <div
            className="multiSearch"
            style={{
              marginLeft: isMobile ? "0" : "10px",
              marginTop: "4px",
              flex: isMobile ? "50%" : "initial",
            }}
          >
            <Dropdown
              menu={{
                items: client_request,
              }}
              trigger={["click"]}
            >
              <div
                style={{
                  fontSize: "12px",
                  marginRight: "10px",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                }}
              >
                <CaretDownOutlined /> {t("search_by")}
                <span style={{ color: Color.blue_2 }}>{filterBy?.label}</span>
              </div>
            </Dropdown>
            <Search
              style={{
                marginTop: "4px",
                paddingLeft: "6px",
              }}
              value={filterSearchText}
              placeholder={t("search")}
              onSearch={(e: any) => setSearch?.(e)}
              onChange={(e: any) => {
                setFilterSearchText(e.target.value);
                if (e.target.value === "") setSearch?.("");
                else if (e.target.value.length > 2) setSearch?.(e.target.value);
              }}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: isMobile ? "center" : "flex-end",
            width: isMobile ? "100%" : "initial",
          }}
        >
          {isDone != undefined && (
            <div style={{ flex: isMobile ? "50%" : "initial" }}>
              <span className="label">{t("done")}</span>
              <Switch
                defaultChecked={false}
                checked={isDone == true}
                onChange={(e: any) => (e ? setIsDone?.(1) : setIsDone?.(0))}
                onClick={(e: any) => (e ? setIsDone?.(1) : setIsDone?.(0))}
              />
            </div>
          )}
        </div>

        {/* {setDate !== undefined && !isMobile && (
          <ConfigProvider direction="ltr">
            <RangePicker
              style={{ marginLeft: "10px" }}
              disabledDate={(current) => {
                return (
                  current.isBefore(dayjs("2022-01-01")) ||
                  current.isAfter(dayjs().subtract(-1, "day"))
                );
              }}
              value={
                DateRange !== ""
                  ? [
                      dayjs(DateRange?.split(",")[0]),
                      dayjs(DateRange?.split(",")[1]),
                    ]
                  : undefined
              }
              onChange={(e: any) => ChangeDate(e)}
              showTime
              format={format.date}
            />
          </ConfigProvider>
        )} */}
      </div>

      {/* {User?.role_id == Roles.technical &&
        setScheduleModalIsOpen &&
        hasPermission(["browse_schedules"]) && (
          <Button type="primary" onClick={() => setScheduleModalIsOpen(true)}>
            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <AiOutlineSchedule />
              <p style={{ margin: 0 }}>{t("appointments")}</p>
            </div>
          </Button>
        )} */}
    </div>
  );
};

export default PlansFilters;
