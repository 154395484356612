import { ApiError } from "utils/api/models/api-error";
import { useQuery } from "react-query";
import ApiResult from "utils/api/models/api-result";
import { FetchTransportations } from "services/transportation";
import { TransportationTypeModel } from "models/transporation";

export default function useTransportation() {
  const queryKey = ["transportation"];
  return {
    ...useQuery<ApiResult<TransportationTypeModel[]>, ApiError>(
      queryKey,
      () => FetchTransportations(),
      {
        staleTime: Infinity,
      }
    ),
  };
}
