import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, List, Modal, Row, Tag } from "antd";
import useAcceptPlan from "hooks/query/plan/useAcceptPlan";
import useRejectPlan from "hooks/query/plan/useRejectPlan";
import { PlanModel } from "models/plan";
import { useTranslation } from "react-i18next";
import Color from "utils/helpers/export-sass-colors";
import { StatusColor } from "utils/helpers/ticket_status";
import { useWidth } from "utils/helpers/use-is-mobile";
import { hasPermission } from "components/permissionsGate";
import { Ticket } from "utils/helpers/static_data";

type Props = {
  plan: PlanModel;
  closeModal: Function;
  open: boolean;
};
const { confirm } = Modal;

const daysMatching: any = {
  Saturday: "السبت",
  Sunday: "الاحد",
  Monday: "الاثنين",
  Tuesday: "الثلاثاء",
  Wednesday: "الاربعاء",
  Thursday: "الخميس",
  Friday: "الجمعة",
};

function PlanDetailsModal({ plan, closeModal, open }: Props) {
  const { t } = useTranslation();
  const acceptPlan = useAcceptPlan();
  const rejectPlan = useRejectPlan();
  const { isMobile } = useWidth();

  console.log(plan);

  if (!plan) return null;
  return (
    <>
      <Modal
        key={"planModal" + plan.id}
        width={isMobile ? "100%" : 700}
        centered
        onCancel={() => closeModal()}
        open={open}
        footer={[
          <Button
            type="primary"
            onClick={() => closeModal()}
            className="closeBtn"
            key="back"
          >
            {t("close")}
          </Button>,

          <div style={{ display: "flex" }}>
            {hasPermission(["reject_plans"]) &&
              plan?.status?.id.toString() == Ticket.pending && (
                <Button
                  key="add"
                  className="addRwview"
                  onClick={() => {
                    confirm({
                      title: t("sure_reject_plan"),
                      icon: <ExclamationCircleOutlined />,
                      okButtonProps: {
                        children: t("reject"),
                      },
                      cancelButtonProps: {
                        children: t("cancel"),
                      },
                      okText: t("reject"),
                      cancelText: t("cancel"),
                      onOk() {
                        rejectPlan
                          .mutateAsync(plan.id)
                          .then(() => {
                            closeModal();
                            return true;
                          })
                          .then(() => {});
                      },
                      onCancel() {},
                    });
                  }}
                  type="primary"
                  loading={rejectPlan.isLoading}
                  style={{ background: Color.red, marginLeft: 10 }}
                >
                  {t("reject_plan")}
                </Button>
              )}
          </div>,
          <div style={{ display: "flex" }}>
            {hasPermission(["accept_plans"]) &&
              plan?.status?.id.toString() == Ticket.pending && (
                <Button
                  key="add"
                  className="addRwview"
                  onClick={() => {
                    confirm({
                      title: t("sure_accept_plan"),
                      icon: <ExclamationCircleOutlined />,
                      okButtonProps: {
                        children: t("accept"),
                      },
                      cancelButtonProps: {
                        children: t("cancel"),
                      },
                      okText: t("accept"),
                      cancelText: t("cancel"),
                      onOk() {
                        acceptPlan
                          .mutateAsync(plan.id)
                          .then(() => {
                            closeModal();
                            return true;
                          })
                          .then(() => {});
                      },
                      onCancel() {},
                    });
                  }}
                  type="primary"
                  loading={acceptPlan.isLoading}
                  style={{ background: Color.green, marginLeft: 10 }}
                >
                  {t("accept_plan")}
                </Button>
              )}
          </div>,
        ]}
      >
        <Row
          justify="space-between"
          style={{ padding: "20px" }}
          gutter={[20, 20]}
        >
          <Col xs={24} md={24}>
            <Card
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p style={{ margin: 0 }}>
                    {t(`plan_details`)}
                    <span>{` #${plan.id}`}</span>
                  </p>
                  <Tag color={StatusColor(plan?.status?.id)}>
                    {plan?.status.status}
                  </Tag>
                </div>
              }
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{
                    marginBottom: "8px",
                    fontSize: "16px",
                  }}
                >
                  {t("start_date")} : {plan.start_date}
                </p>
                <p
                  style={{
                    marginBottom: "8px",
                    fontSize: "16px",
                  }}
                >
                  {t("end_date")} : {plan.end_date}
                </p>
              </div>
              <p
                style={{
                  marginBottom: "8px",
                  fontSize: "16px",
                }}
              >
                {t("plan_creator")} : {plan.created_by.full_name}
              </p>
              <div style={{ display: "flex", width: "100%", gap: "10px" }}>
                <p
                  style={{
                    marginBottom: "8px",
                    fontSize: "16px",
                  }}
                >
                  {t("total_tickets")} : {plan.visits_number}
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} md={24}>
            <Card title={t("clients")}>
              <List itemLayout="horizontal">
                <List.Item>
                  <div>
                    <span>السبت: </span>
                    {plan.users
                      .filter((user) => user.day === "Saturday")
                      .map((user) => (
                        <Tag style={{ fontSize: "14px", fontWeight: "bold" }}>
                          {user.full_name}
                        </Tag>
                      ))}
                  </div>
                </List.Item>
                <List.Item>
                  <div>
                    <span>الأحد: </span>
                    {plan.users
                      .filter((user) => user.day === "Sunday")
                      .map((user) => (
                        <Tag style={{ fontSize: "14px", fontWeight: "bold" }}>
                          {user.full_name}
                        </Tag>
                      ))}
                  </div>
                </List.Item>
                <List.Item>
                  <div>
                    <span>الإثنين: </span>
                    {plan.users
                      .filter((user) => user.day === "Monday")
                      .map((user) => (
                        <Tag style={{ fontSize: "14px", fontWeight: "bold" }}>
                          {user.full_name}
                        </Tag>
                      ))}
                  </div>
                </List.Item>
                <List.Item>
                  <div>
                    <span>الثلاثاء: </span>
                    {plan.users
                      .filter((user) => user.day === "Tuesday")
                      .map((user) => (
                        <Tag style={{ fontSize: "14px", fontWeight: "bold" }}>
                          {user.full_name}
                        </Tag>
                      ))}
                  </div>
                </List.Item>
                <List.Item>
                  <div>
                    <span>الأربعاء: </span>
                    {plan.users
                      .filter((user) => user.day === "Wednesday")
                      .map((user) => (
                        <Tag style={{ fontSize: "14px", fontWeight: "bold" }}>
                          {user.full_name}
                        </Tag>
                      ))}
                  </div>
                </List.Item>
              </List>
              {/* <Row gutter={[20, 20]}>
                <Col xs={24} md={12} lg={8}>
                  <List
                    header={<span style={{ color: Color.blue }}>السبت</span>}
                    itemLayout="horizontal"
                    style={{ maxHeight: 250, overflow: "auto" }}
                    dataSource={plan.users.filter(
                      (user) => user.day === "Saturday"
                    )}
                    bordered
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "0 20px",
                              }}
                            >
                              {item.full_name}
                            </span>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Col>

                <Col xs={24} md={12} lg={8}>
                  <List
                    header={<span style={{ color: Color.blue }}>الأحد</span>}
                    itemLayout="horizontal"
                    style={{ maxHeight: 250, overflow: "auto" }}
                    dataSource={plan.users.filter(
                      (user) => user.day === "Sunday"
                    )}
                    bordered
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "0 20px",
                              }}
                            >
                              {item.full_name}
                            </span>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <List
                    header={<span style={{ color: Color.blue }}>الإثنين</span>}
                    itemLayout="horizontal"
                    style={{ maxHeight: 250, overflow: "auto" }}
                    dataSource={plan.users.filter(
                      (user) => user.day === "Monday"
                    )}
                    bordered
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "0 20px",
                              }}
                            >
                              {item.full_name}
                            </span>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <List
                    header={<span style={{ color: Color.blue }}>الثلاثاء</span>}
                    itemLayout="horizontal"
                    style={{ maxHeight: 250, overflow: "auto" }}
                    dataSource={plan.users.filter(
                      (user) => user.day === "Tuesday"
                    )}
                    bordered
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "0 20px",
                              }}
                            >
                              {item.full_name}
                            </span>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <List
                    header={<span style={{ color: Color.blue }}>الأربعاء</span>}
                    itemLayout="horizontal"
                    style={{ maxHeight: 250, overflow: "auto" }}
                    dataSource={plan.users.filter(
                      (user) => user.day === "Wednesday"
                    )}
                    bordered
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "0 20px",
                              }}
                            >
                              {item.full_name}
                            </span>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Col>
              </Row> */}
            </Card>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default PlanDetailsModal;
