import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Spin, notification } from "antd";
import PermissionsGate, { hasPermission } from "components/permissionsGate";

import Comments from "components/ticket/forms/comments";
import Reports from "components/ticket/forms/reports";
import Schedule from "components/ticket/forms/schedule";
import useCurrentLocation from "hooks/others/useCurrentLocation";
import { useLocation as useGeoLocation } from "hooks/others/useLocation";
import CheckinReport from "hooks/query/reports/CheckinReports";
import DeleteTicket from "hooks/query/tickets/deleteTicket";
import useTicket from "hooks/query/tickets/useTicket";
import { t } from "i18next";
import { SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Ticket } from "utils/helpers/static_data";
import { StatusColor } from "utils/helpers/ticket_status";

const { Option } = Select;
const { confirm } = Modal;

export function VisitModal({
  open,
  setOpen,
  id,
  displayCheckin = false,
}: {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  id: number | undefined;
  displayCheckin?: boolean;
}) {
  const { data, isLoading, refetch, isFetching, error } = useTicket(id!);

  const location = useLocation();

  const getLocationOnClick = useCurrentLocation();

  const navigate = useNavigate();

  const Add = CheckinReport();

  const [form] = Form.useForm();
  useEffect(() => {
    if (data) {
      form.setFieldValue("leader", data.data.leader?.full_name);
      form.setFieldValue(
        "doctor",
        data?.data.user
          ? {
              label: data.data.user?.full_name,
              value: data.data.user?.id,
            }
          : undefined
      );
    }
  }, [id, open, data]);

  const handleSubmit = async (e: any) => {
    const formData = new FormData();

    const newLocation = await getLocationOnClick();
    const { latitude, longitude } = newLocation;

    if (latitude !== null && longitude !== null) {
      formData.append("lat", latitude.toString());
      formData.append("lon", longitude.toString());

      Add.mutateAsync({ id, data: formData }).then(() => {
        notification.open({
          type: "success",
          message: t("visit_checked_in_successfully"),
        });
      });
    } else {
      notification.open({
        type: "warning",
        message: t("please_install_app_to_get_location"),
        duration: 7,
      });
    }
  };

  const deleteTicket = DeleteTicket();

  const handleDeleteTicket = () => {
    deleteTicket.mutateAsync(id).then(() => {
      return true;
    });
  };

  const deleteTicketConfirm = () => {
    confirm({
      title: t("delete_ticket_confirm_message"),
      icon: <ExclamationCircleOutlined />,
      okText: t("delete"),
      okType: "danger",
      cancelText: t("cancel"),
      onOk() {
        handleDeleteTicket();
      },
      onCancel() {},
    });
  };

  return (
    <Modal
      width={800}
      centered
      open={open}
      onCancel={() => {
        const { pathname } = location;
        navigate(pathname, { replace: true });
        setOpen(false);
      }}
      className="ticket"
      bodyStyle={{
        padding: "0 1rem",
      }}
      confirmLoading={Add.isLoading}
      okButtonProps={{ disabled: isLoading }}
      footer={[
        <Button
          onClick={() => {
            const { pathname } = location;
            navigate(pathname, { replace: true });
            setOpen(false);
          }}
        >
          {t("cancel")}
        </Button>,
        data &&
          data?.data.ticket_status.id.toString() == Ticket.pending &&
          hasPermission(["add_ticket_reports"]) &&
          displayCheckin && (
            <Button
              loading={Add.isLoading}
              onClick={form.submit}
              type="primary"
            >
              {t("checkin")}
            </Button>
          ),
        <PermissionsGate scopes={["delete_tickets"]}>
          {data?.data.ticket_status.id.toString() !== Ticket.create && (
            <Button
              htmlType="button"
              loading={deleteTicket.isLoading}
              type="primary"
              danger
              onClick={deleteTicketConfirm}
              className="finishBtn red"
            >
              {t("delete_ticket")}
            </Button>
          )}
        </PermissionsGate>,
      ]}
    >
      {data && (!isLoading || !isFetching) ? (
        <div>
          <div
            className="modal-header"
            style={{
              backgroundColor: StatusColor(data?.data?.ticket_status?.id!),
            }}
          >
            <h3 className="title">
              {t("ticket_details")} # {data?.data?.id}
            </h3>
          </div>
          <Form
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            style={{ marginTop: "2rem" }}
          >
            <Form.Item name="leader" label={<div>{t("technicals")}</div>}>
              <Input readOnly />
            </Form.Item>
            <Form.Item
              name="doctor"
              rules={[
                { required: true, message: t("please_select") + " ${label}" },
              ]}
              label={<>{t("client_1")}</>}
            >
              <Select disabled={true}>
                <Option key={data?.data?.user.id}>
                  {data?.data?.user.full_name}
                </Option>
              </Select>
            </Form.Item>

            <Schedule
              ticket={data?.data}
              Status={data?.data?.ticket_status?.id.toString()}
            />
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              <Reports
                ticket={data?.data!}
                Status={data?.data?.ticket_status?.id.toString()}
                refetch={() => refetch()}
              />
              <Comments
                ticket={data?.data}
                Status={data?.data?.ticket_status?.id.toString()}
                refetch={() => refetch()}
              />
            </div>
          </Form>
        </div>
      ) : (
        <div className="SpinCon">
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
            size="large"
          />
        </div>
      )}
    </Modal>
  );
}
