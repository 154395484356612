const get = (key: string) => {
  const settingStr: string | null =
    window.localStorage.getItem("SETTINGS") ?? null;
  if (settingStr) {
    const setting = JSON.parse(settingStr);
    const value = setting[key];
    if (value) return value;
    return null;
  }
  return undefined;
};

const set = (key: string, value: any): void => {
  const settingStr: string | null =
    window.localStorage.getItem("SETTINGS") ?? null;
  let setting;
  if (settingStr) setting = JSON.parse(settingStr);
  setting = {
    ...setting,
    [key]: value,
  };
  window.localStorage.setItem("SETTINGS", JSON.stringify(setting));
};

const Settings = {
  get,
  set,
};

export default Settings;
