import { isAbsolute } from "path";
import Color from "utils/helpers/export-sass-colors";

interface PageTitleProps {
  children: any;
  isAbsolute?: boolean;
  color?: any;
}

const PageTitle = ({ children, isAbsolute = false, color }: PageTitleProps) => {
  return (
    <div
      style={{
        textAlign: "center",
        padding: "14px 0",
        borderRadius: isAbsolute ? "" : "15px",
        backgroundColor: color || Color.blue,
        position: isAbsolute ? "absolute" : "relative",
        top: "0",
        left: "0",
        width: "100%",
      }}
    >
      <h2
        style={{
          margin: "0",
          color: "white",
          fontSize: "26px",
        }}
      >
        {children}
      </h2>
    </div>
  );
};
export default PageTitle;
