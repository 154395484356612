import { CommentsModel } from "models/comments/index";
import { AxiosRequestConfig } from "axios";
import ApiService from "utils/api/api-service";
import ApiResponse, { isError } from "utils/api/models/api-response";
import { ApiErrorNotification } from "utils/ui/notificationService";

class CommentsService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: process.env.REACT_APP_API_URL,
      ...config,
    });
  }

  public async Fetch(id: number): Promise<ApiResponse<CommentsModel[]>> {
    return this.get(
      `/api/tickets/${id}/comments?all=1&sort=created_at&index_type=list`
    );
  }
  public async Post(
    id: number,
    content: string
  ): Promise<ApiResponse<CommentsModel[]>> {
    return this.post(`/api/tickets/${id}/comments`, content);
  }
}

const commentsService = new CommentsService();

export const PostComment = async (obj: { id: number; content: string }) => {
  const result: any = await commentsService.Post(obj.id, obj.content);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const FetchComments = async (id: number, show: boolean) => {
  if (id !== 0 && show) {
    const result: any = await commentsService.Fetch(id);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
