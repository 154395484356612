import { AxiosRequestConfig } from "axios";
import ApiService from "utils/api/api-service";
import ApiResponse, { isError } from "utils/api/models/api-response";
import { ApiErrorNotification } from "utils/ui/notificationService";

class TransportationService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: process.env.REACT_APP_API_URL,
      ...config,
    });
  }

  public async Fetch(): Promise<ApiResponse<any>> {
    return this.get(`/api/transportations`);
  }
  public async PostCheckinTransportation(
    id: any,
    data: any
  ): Promise<ApiResponse<any>> {
    return this.post(`/api/tickets/${id}/transportation-report-check-in`, data);
  }
  public async PostCheckoutTransportation(
    ticketId: any,
    reportId: any,
    data: any
  ): Promise<ApiResponse<any>> {
    return this.post(
      `/api/tickets/${ticketId}/${reportId}/transportation-report-check-out`,
      data
    );
  }
  public async Updata(id: any, data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/transportationReport/${id}`, data);
  }
}

const reportsService = new TransportationService();

export const FetchTransportations = async () => {
  const result: any = await reportsService.Fetch();
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const AddCheckinTransportation = async (obj: any) => {
  const result: any = await reportsService.PostCheckinTransportation(
    obj.id,
    obj.data
  );
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const AddCheckoutTransportation = async (obj: any) => {
  const result: any = await reportsService.PostCheckoutTransportation(
    obj.ticketId,
    obj.reportId,
    obj.data
  );
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const UpdateTransportaion = async (obj: any) => {
  if (obj.id !== 0) {
    const result: any = await reportsService.Updata(obj.id, obj.data);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
