import { Button, Form, Input } from "antd";
import Title from "antd/es/typography/Title";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useQueryClient } from "react-query";
import InputRule from "utils/helpers/input-roles";
import useLogin from "hooks/auth/login";
import InputPhone from "components/inputPhone";
import { t } from "i18next";

const LoginForm = ({}: {}) => {
  const [loginForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const loginUser = useLogin();

  const Login = (e: any) => {
    setIsLoading(true);
    const username = e.username;
    let password: string = e.password;

    loginUser
      .mutateAsync({ user_name: username, password })
      .then(() => {
        queryClient.invalidateQueries("NotificationsNumber");
        queryClient.invalidateQueries("tickets");
        queryClient.removeQueries("CheckUser");
        queryClient.removeQueries("CurrentUser");
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <Title
        style={{ textAlign: "center", marginBlock: "20px 30px" }}
        level={5}
      >
        {t("login")}
      </Title>
      <Form form={loginForm} onFinish={Login}>
        {/* <Form.Item
          name={"phone_number"}
          rules={[
            {
              validator(rule, value) {
                if (!value.phone || value.phone.length < 1) {
                  return Promise.reject(
                    new Error(t("please_input") + " " + t("phone_number"))
                  );
                } else if (value.phone.length >= 17) {
                  return Promise.reject(new Error(t("invalid_phone_number")));
                } else if (value.phone.length < 6) {
                  return Promise.reject(new Error(t("invalid_phone_number")));
                }
                return Promise.resolve();
              },
            },
          ]}
          initialValue={{ short: "SY", code: 90 }}
        >
          <InputPhone className="phoneNumberInput" />
        </Form.Item> */}
        <Form.Item
          name={"username"}
          rules={[
            {
              required: true,
              message: t("please_input") + " " + t("user_name"),
            },
          ]}
        >
          <Input className="textInput" placeholder={t("user_name")} />
        </Form.Item>
        <Form.Item
          name={"password"}
          rules={[
            {
              required: true,
              message: t("please_input") + " " + t("password"),
            },
            ...InputRule.Password,
          ]}
        >
          <Input.Password className="passwordInput" placeholder="Password" />
        </Form.Item>
        {/* <Form.Item>
          <Link to={{ pathname: "/forget" }} className="forgetLink">
            {t("forgot_password")}
          </Link>
        </Form.Item> */}
        <Form.Item>
          <Button
            shape="round"
            htmlType="submit"
            className="submitButton"
            type="primary"
            loading={isLoading}
          >
            {t("login")}
          </Button>
        </Form.Item>
        {/* <Form.Item>
          <div style={{ fontSize: "13px", textAlign: "center" }}>
            {t("dont_have_an_account")}{" "}
            <Link to={{ pathname: "/register" }} className="forgetLink">
              {t("create_account")}
            </Link>
          </div>
        </Form.Item> */}
      </Form>
    </>
  );
};

export default LoginForm;
