import { useInfiniteQuery, useQuery } from "react-query";
import { FetchUsersCompany } from "services/companies";
import ApiResult from "utils/api/models/api-result";
import { ApiError } from "utils/api/models/api-error";
import { UserModel } from "models/users";
import useDebounce from "hooks/others/useDebounce";

export default function useUsersCompany(
  id: any,
  page?: number,
  perPage?: any,
  enabled: boolean = true,
  search?: string
) {
  const { newParam: searchParam } = useDebounce(search ? search : "");
  const queryKey = ["usersCompanies", id, perPage, searchParam];
  return {
    ...useInfiniteQuery<ApiResult<UserModel[]>, ApiError>(
      queryKey,
      async ({ pageParam = 1 }) =>
        await FetchUsersCompany(id, page, perPage, searchParam),
      {
        refetchOnWindowFocus: false,
        staleTime: 15000,
        cacheTime: 15000,
        enabled: enabled,
        keepPreviousData: true,
        getNextPageParam: (lastPage) =>
          lastPage?.meta?.currentPage! + 1 ?? false,
      }
    ),
  };
}

export function useDoctors(
  id: any,
  page?: number,
  perPage?: any,
  enabled: boolean = true,
  search?: string
) {
  const { newParam: searchParam } = useDebounce(search ? search : "");
  const queryKey = ["usersCompanies", id, perPage, searchParam];
  return {
    ...useQuery<ApiResult<UserModel[]>, ApiError>(
      queryKey,
      async ({ pageParam = 1 }) =>
        await FetchUsersCompany(id, page, perPage, searchParam),
      {
        refetchOnWindowFocus: false,
        staleTime: 15000,
        cacheTime: 15000,
        enabled: enabled,
        keepPreviousData: true,
        getNextPageParam: (lastPage) =>
          lastPage?.meta?.currentPage! + 1 ?? false,
      }
    ),
  };
}
