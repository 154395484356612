import { postSchedules } from "services/schedule";
import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { notification } from "antd";
import { isError } from "utils/api/models/api-response";
import i18next from "i18next";

export default function AddSchedule() {
  const queryClient = useQueryClient();
  return {
    ...useMutation(postSchedules, {
      onSuccess: async (res) => {
        if (!isError(res)) {
          notification.open({
            message:
              i18next.language === "ar"
                ? "تم إرسال الوقت المقدر"
                : "Estimated time has been sent",
          });
          queryClient.invalidateQueries(["ticket", res.data.id]);
          queryClient.invalidateQueries("ticket");
          queryClient.invalidateQueries("Reports");
        }
      },
    }),
  };
}
