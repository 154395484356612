import {
  CloseOutlined,
  LoadingOutlined,
  MessageFilled,
} from "@ant-design/icons";
import { Button, Form, Input, Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import AddComment from "hooks/query/comments/addComment";
import useComments from "hooks/query/comments/useComments";
import { useWidth } from "utils/helpers/use-is-mobile";
import { Ticket } from "utils/helpers/static_data";
import PermissionsGate, { PermissionsUpdate } from "components/permissionsGate";
import format from "utils/helpers/date-format";
import dayjs from "dayjs";
import { UserModel } from "models/users";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import i18next, { t } from "i18next";

const Comments = ({ ticket, Status, refetch, notEvent }: any) => {
  let { id }: any = useParams();

  const [CommentModalVisible, setCommentModalVisible] = useState(false);
  const [commentForm] = Form.useForm();

  const [notificationsCount, setNotificationsCount] = useState<number>();

  const CommentsData = useComments(ticket.id, CommentModalVisible);
  const PostComment = AddComment(commentForm);

  const queryClient = useQueryClient();

  const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

  const navigate = useNavigate();
  const location = useLocation();

  const openComments = () => {
    CommentsData.refetch().then(() => {
      setCommentModalVisible(true);
      queryClient.invalidateQueries("NotificationsNumber");
    });
  };

  const CloseCommentsModal = () => {
    setCommentModalVisible(false);
    if (notEvent && notEvent === "Comment") {
      const { pathname } = location;
      navigate(pathname, { replace: true });
    }
  };

  useEffect(() => {
    if (CommentModalVisible) {
      setNotificationsCount(0);
    }
  }, [CommentModalVisible]);

  const addComent = (e: any) => {
    PostComment.mutateAsync({
      id: parseInt(ticket?.id),
      content: e,
    });
  };

  useEffect(() => {
    if (notEvent && notEvent === "Comment") {
      setCommentModalVisible(true);
    }
  }, [notEvent]);

  const { isMobile } = useWidth();

  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserData.Decrypt]);

  useEffect(() => {
    let objDiv: any = document.getElementById("comments");
    if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
  }, [CommentsData.data?.data]);

  useEffect(() => {
    if (ticket && ticket.notifications_count) {
      setNotificationsCount(ticket?.notifications_count?.comments_count);
    }
  }, [ticket]);

  return (
    <PermissionsGate scopes={["browse_comments"]}>
      <div
        onClick={() => openComments()}
        className={
          Status === Ticket.create ? "addRwview disabled" : "addRwview"
        }
      >
        {t("comments")} <MessageFilled />
        {notificationsCount !== undefined && notificationsCount !== 0 && (
          <div className="notificationNum">{notificationsCount}</div>
        )}
      </div>

      <Modal
        key={"commentModal"}
        width={700}
        onCancel={() => CloseCommentsModal()}
        title={t("comments")}
        open={CommentModalVisible}
        centered
        className="commentModal"
        closeIcon={<CloseOutlined />}
        bodyStyle={{ padding: "0 24px" }}
        footer={null}
      >
        {ticket && (
          <div>
            <div id={"comments"} className={"comments"}>
              {!CommentsData.isLoading &&
                CommentsData.data?.data?.map((comment) => (
                  <div
                    style={{ padding: " 30px 20px", marginBottom: 20 }}
                    className="greyBox commnetBox"
                    key={comment.id}
                  >
                    <div className="header">
                      <div className="date">
                        <span className="data">
                          {dayjs(comment.created_at).format(format.date_time)}
                        </span>
                      </div>
                      {comment?.user && (
                        <PermissionsUpdate scopes={["read_users"]}>
                          <div>
                            <div className="reportUserInfo">
                              <img src={comment?.user?.avatar} alt="" />
                              <div className="username">
                                {comment?.user?.full_name !== null &&
                                  comment?.user?.full_name}
                                <span>{comment?.user?.job_title?.name}</span>
                              </div>
                            </div>
                          </div>
                        </PermissionsUpdate>
                      )}
                    </div>

                    <div
                      dir="auto"
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: comment.comment_content as any,
                      }}
                    />
                  </div>
                ))}
              {!CommentsData.isLoading &&
                CommentsData.data?.data?.length === 0 && (
                  <div style={{ textAlign: "center", padding: "20px" }}>
                    - {t("no_comments")} -
                  </div>
                )}
              {CommentsData.isLoading && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    paddingBlock: "10vh",
                    alignItems: "center",
                  }}
                  className="SpinCon"
                >
                  <Spin indicator={antIcon} size="large" />
                </div>
              )}
            </div>

            <PermissionsGate scopes={["add_comments"]}>
              <div
                style={{
                  padding: " 15px 20px 15px",
                }}
                className="greyBox"
              >
                <Form
                  layout={isMobile ? "horizontal" : "vertical"}
                  onFinish={addComent}
                  form={commentForm}
                  style={{ display: "flex" }}
                  requiredMark={false}
                >
                  <Form.Item
                    label={t("add_comment")}
                    name="content"
                    rules={[
                      {
                        required: true,
                        message:
                          i18next.language === "ar"
                            ? "يرجى ادخال تعليق"
                            : "Please input comment",
                      },
                    ]}
                    style={{ width: "100%", margin: 0 }}
                  >
                    <Input type="text" dir="auto" autoComplete="off" />
                  </Form.Item>
                  <Button
                    loading={PostComment.isLoading}
                    style={{
                      borderRadius: 5,
                      margin: "auto 5px 5px",
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    {t("send")}
                  </Button>
                </Form>
              </div>
            </PermissionsGate>
          </div>
        )}
      </Modal>
    </PermissionsGate>
  );
};

export default Comments;
